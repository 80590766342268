import React, { useRef, useState } from "react";
import { createTheme } from "@mui/material/styles";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Collapse,
  Box,
  Divider,
  Switch,
  Typography,
  styled,
  ThemeProvider,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  ArrowBack as ArrowBackIcon,
  Menu as MenuIcon,
  Home as HomeIcon,
  Info as InfoIcon,
  ContactMail as ContactMailIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "black",
    color: "white",
    fontSize: 12,
  },
});

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
    width: 32,
    height: 32,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(true);
  const closeTimeout = useRef(null);

  const handleDrawerToggle = () => {
    setOpen(!open);
    setDropdownOpen(false);
  };

  const handleDropdownClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleThemeChange = (event) => {
    setDarkMode(event.target.checked);
  };

  const handleMouseEnter = () => {
    if (closeTimeout.current) {
      clearTimeout(closeTimeout.current);
      closeTimeout.current = null;
    }
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    closeTimeout.current = setTimeout(() => {
      setDropdownOpen(false);
    }, 300); // Adjust the delay as needed
  };

  const lightTheme = createTheme({
    palette: {
      mode: "light",
      background: {
        default: "#f4f6f8",
        paper: "#fff",
      },
      text: {
        primary: "#000",
      },
    },
  });

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      background: {
        default: "#212930",
        paper: "#181F26",
      },
      text: {
        primary: "#9fb4c7",
      },
    },
  });

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <div
        style={{
          display: "flex",
          backgroundColor: darkMode ? "#212930" : "#f4f6f8",
          height: "100vh",
        }}
      >
        <Drawer
          variant="permanent"
          open={open}
          PaperProps={{
            sx: {
              backgroundColor: darkMode ? "#212930" : "#fff",
              color: darkMode ? "#9fb4c7" : "#000",
              width: open ? 220 : 60,
              transition: "width 0.3s",
              overflowX: "hidden",
              position: "relative",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "8px",
              justifyContent: open ? "space-between" : "center",
            }}
          >
            <CustomTooltip title={open ? "Close" : "Open"} placement="right">
              <IconButton
                onClick={handleDrawerToggle}
                sx={{ color: darkMode ? "#9fb4c7" : "#000" }}
              >
                {open ? <ArrowBackIosIcon /> : <MenuIcon />}
              </IconButton>
            </CustomTooltip>
            {open && (
              <h5
                style={{ color: darkMode ? "white" : "black", margin: "5px" }}
              >
                <span
                  style={{
                    background: darkMode ? "#198754" : "#4CAF50",
                    borderRadius: "5px",
                    padding: "3px",
                    fontStyle: "italic",
                  }}
                >
                  Kisan
                </span>
                <span style={{ fontStyle: "italic", marginRight: "5px" }}>
                  360
                </span>
              </h5>
            )}
          </Box>
          <Divider sx={{ backgroundColor: darkMode ? "#555" : "#000" }} />

          {/* //// Drawer content add here */}
          <List>
            <CustomTooltip
              title="Home"
              placement="right"
              disableHoverListener={open}
            >
              <ListItem
                button
                sx={{
                  "&:hover": { backgroundColor: "#323d49", color: "white" },
                  "&:hover .MuiListItemIcon-root": { color: "white" },
                }}
              >
                <ListItemIcon
                  sx={{ color: darkMode ? "#9fb4c7" : "#000", minWidth: 40 }}
                >
                  <CalendarTodayIcon />
                </ListItemIcon>
                {open && (
                  <ListItemText
                    primary="Home"
                    sx={{ fontFamily: "inherit", fontSize: 14 }}
                  />
                )}
              </ListItem>
            </CustomTooltip>
            <CustomTooltip
              title="About"
              placement="right"
              disableHoverListener={open}
            >
              <ListItem
                button
                sx={{
                  "&:hover": { backgroundColor: "#323d49", color: "white" },
                  "&:hover .MuiListItemIcon-root": { color: "white" },
                }}
              >
                <ListItemIcon
                  sx={{ color: darkMode ? "#9fb4c7" : "#000", minWidth: 40 }}
                >
                  <InfoIcon />
                </ListItemIcon>
                {open && (
                  <ListItemText
                    primary="About"
                    sx={{ fontFamily: "inherit", fontSize: 14 }}
                  />
                )}
              </ListItem>
            </CustomTooltip>
            <CustomTooltip
              title="Contact"
              placement="right"
              disableHoverListener={open}
            >
              <ListItem
                button
                sx={{
                  "&:hover": { backgroundColor: "#323d49", color: "white" },
                  "&:hover .MuiListItemIcon-root": { color: "white" },
                }}
              >
                <ListItemIcon
                  sx={{ color: darkMode ? "#9fb4c7" : "#000", minWidth: 40 }}
                >
                  <ContactMailIcon />
                </ListItemIcon>
                {open && (
                  <ListItemText
                    primary="Contact"
                    sx={{ fontFamily: "inherit", fontSize: 14 }}
                  />
                )}
              </ListItem>
            </CustomTooltip>

            <ListItem
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              button
              onClick={handleDropdownClick}
              sx={{
                "&:hover": { backgroundColor: "#323d49", color: "white" },
                "&:hover .MuiListItemIcon-root": { color: "white" },
              }}
            >
              <ListItemIcon
                sx={{ color: darkMode ? "#9fb4c7" : "#000", minWidth: 40 }}
              >
                <SettingsIcon />
              </ListItemIcon>
              {open && (
                <ListItemText
                  primary="Settings"
                  sx={{ fontFamily: "inherit", fontSize: 14 }}
                />
              )}
              {open ? (
                dropdownOpen ? (
                  <ExpandLess sx={{ color: "#9fb4c7" }} />
                ) : (
                  <ExpandMore sx={{ color: "#9fb4c7" }} />
                )
              ) : null}
            </ListItem>
            <Collapse in={dropdownOpen && open} timeout="auto" unmountOnExit>
              <List
                component="div"
                disablePadding
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <ListItem
                  button
                  sx={{
                    pl: 7.1,
                    color: darkMode ? "#9fb4c7" : "#000",
                    background: darkMode ? "#181F26" : "#f4f6f8",
                    "&:hover": { backgroundColor: "#323d49", color: "white" },
                  }}
                >
                  <ListItemText
                    primary="Sowing"
                    primaryTypographyProps={{
                      fontFamily: "inherit",
                      fontSize: 15, // Set the desired font size here
                    }}
                  />
                </ListItem>
                <ListItem
                  button
                  sx={{
                    pl: 7.1,
                    color: darkMode ? "#9fb4c7" : "#000",
                    background: darkMode ? "#181F26" : "#f4f6f8",
                    "&:hover": { backgroundColor: "#323d49", color: "white" },
                  }}
                >
                  <ListItemText
                    primary="Nitrogen"
                    primaryTypographyProps={{
                      fontFamily: "inherit",
                      fontSize: 15, // Set the desired font size here
                    }}
                  />
                </ListItem>
                <ListItem
                  button
                  sx={{
                    pl: 7.1,
                    color: darkMode ? "#9fb4c7" : "#000",
                    background: darkMode ? "#181F26" : "#f4f6f8",
                    "&:hover": { backgroundColor: "#323d49", color: "white" },
                  }}
                >
                  <ListItemText
                    primary="P&K"
                    primaryTypographyProps={{
                      fontFamily: "inherit",
                      fontSize: 15, // Set the desired font size here
                    }}
                  />
                </ListItem>
                <ListItem
                  button
                  sx={{
                    pl: 7.1,
                    color: darkMode ? "#9fb4c7" : "#000",
                    background: darkMode ? "#181F26" : "#f4f6f8",
                    "&:hover": { backgroundColor: "#323d49", color: "white" },
                  }}
                >
                  <ListItemText
                    primary="Irrigation"
                    primaryTypographyProps={{
                      fontFamily: "inherit",
                      fontSize: 15, // Set the desired font size here
                    }}
                  />
                </ListItem>
              </List>
            </Collapse>
          </List>
          <Divider sx={{ backgroundColor: darkMode ? "#444" : "#000" }} />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: open ? "space-between" : "center",
              padding: "8px",
            }}
          >
            {open && (
              <Typography
                variant="body1"
                sx={{
                  color: darkMode ? "#9fb4c7" : "#000",
                  marginRight: "8px",
                }}
              ></Typography>
            )}
            <MaterialUISwitch checked={darkMode} onChange={handleThemeChange} />
          </Box>
        </Drawer>
        {!open && dropdownOpen && (
          <Box
            sx={{
              position: "absolute",
              left: 60,
              top: 185, // Adjust top value based on the position of the dropdown
              background: darkMode ? "#181F26" : "#d8d8d9",
              width: 150,
              zIndex: 1300,
              fontFamily: "inherit",
              // border: '1px solid #555',
              borderRadius: "20px",
              //   padding: "8px 0",
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <List component="div" disablePadding>
              <ListItem
                button
                sx={{
                  color: darkMode ? "#9fb4c7" : "#000",
                  background: darkMode ? "#181F26" : "#d8d8d9",
                  "&:hover": { backgroundColor: "#323d49", color: "white" },
                }}
              >
                <ListItemText
                  primary="Sowing not open"
                  sx={{ fontFamily: "inherit", fontSize: 14 }}
                />
              </ListItem>
              <ListItem
                button
                sx={{
                  color: darkMode ? "#9fb4c7" : "#000",
                  background: darkMode ? "#181F26" : "#d8d8d9",
                  "&:hover": { backgroundColor: "#323d49", color: "white" },
                }}
              >
                <ListItemText
                  primary="Nitrogen"
                  sx={{ fontFamily: "inherit", fontSize: 14 }}
                />
              </ListItem>
              <ListItem
                button
                sx={{
                  color: darkMode ? "#9fb4c7" : "#000",
                  background: darkMode ? "#181F26" : "#d8d8d9",
                  "&:hover": { backgroundColor: "#323d49", color: "white" },
                }}
              >
                <ListItemText
                  primary="P&K"
                  sx={{ fontFamily: "inherit", fontSize: 14 }}
                />
              </ListItem>
              <ListItem
                button
                sx={{
                  color: darkMode ? "#9fb4c7" : "#000",
                  background: darkMode ? "#181F26" : "#d8d8d9",
                  "&:hover": { backgroundColor: "#323d49", color: "white" },
                }}
              >
                <ListItemText
                  primary="Map builder"
                  sx={{ fontFamily: "inherit", fontSize: 14 }}
                />
              </ListItem>
            </List>
          </Box>
        )}
      </div>
    </ThemeProvider>
  );
};

export default Sidebar;
