import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Feedback from "../Feedback/feedback";
import Adpc from "../Assets/Logo/adpc.png";
import Uaf from "../Assets/Logo/uaf.gif";
import Gzis from "../Assets/Logo/gzisbgremove.png";
import "./about.css"
import {
  ThemeProvider,
  createTheme,
  useTheme,
  styled,
} from "@mui/material/styles";
import {
  Drawer,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  Divider,
  ListItemText,
  IconButton,
  Typography,
} from "@mui/material";
import {
  ArrowBackIos as ArrowBackIosIcon,
  Menu as MenuIcon,
  Home as HomeIcon,
  Info as InfoIcon,
} from "@mui/icons-material";
import KisanLogo from "../Assets/Logo/kisan-bg.png";
import { useMediaQuery } from "@mui/material";
import LogOutDropDown from "../LogOut/logOutDropDown";
import LanguageSwitcher from "../Language-Switcher/languageSwitcher";
import { Tooltip } from "@mui/material";
import MapLoader from "../All-Loader/mapLoader";

const AboutUsContent = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(6),
  maxWidth: "900px",
  margin: "auto",
  backgroundColor: "#2e3b4a",
  borderRadius: "10px",
  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)",
  textAlign: "center",
  overflowY: "auto",
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(4),
    margin: "20px",
  },
}));

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, isRtl }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "black",
    color: "white",
    fontSize: isRtl ? 15 : 12, // Set fontSize based on RTL or LTR
  },
}));

const AboutUsPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const [open, setOpen] = useState(isLargeScreen);
  const isRtl = i18n.dir() === "rtl";
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setOpen(isLargeScreen);
  }, [isLargeScreen]);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const menuItems = [
    {
      text: "About Us",
      icon: <InfoIcon />,
      link: "/about",
    },
    // {
    //   text: "Back to Home",
    //   icon: <HomeIcon />,
    //   link: "/",
    // },
  ];

  const handleGoBack = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      navigate("/"); // Navigate to the home page
    }, 2500); // Set a delay before navigation
  };
  if (loading) return <MapLoader />;

  return (
    <div style={{ backgroundColor: "#212930" }}>
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: "dark",
            background: {
              default: "#212930",
            },
            text: {
              primary: "#9fb4c7",
            },
          },
        })}
      >
        <div style={{ display: "flex", height: "100vh" }}>
          <Drawer
            variant="permanent"
            open={open}
            PaperProps={{
              sx: {
                backgroundColor: "#212930",
                color: "#9fb4c7",
                width: open ? 250 : 60,
                transition: "width 0.3s",
                overflowX: "hidden",
                position: "relative",
                display: "flex",
                flexDirection: "column",
              },
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                overflowY: "auto",
                overflowX: "hidden",
                maxHeight: "calc(100vh)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px",
                  justifyContent: open ? "space-between" : "center",
                }}
              >
                <IconButton
                  onClick={handleDrawerToggle}
                  sx={{ color: "#9fb4c7" }}
                >
                  {open ? <ArrowBackIosIcon /> : <MenuIcon />}
                </IconButton>
                {open && <img src={KisanLogo} alt="logo" width={120} />}
              </Box>
              <Divider sx={{ backgroundColor: "#555" }} />

              {/* Home Icon */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  padding: "8px",
                  justifyContent: "center", // Center the icon horizontally
                  height: "60px", // Set a fixed height for the box
                  backgroundColor: "transparent",
                  "&:hover": {
                    // backgroundColor: "#2a3b4e",
                  },
                }}
                onClick={handleGoBack} // Navigate to home page
              >
                <CustomTooltip title="Back to Home" placement="right">
                  <IconButton>
                    <HomeIcon
                      sx={{
                        color: "#004ba1", // Orange color for the icon
                        fontSize: "2rem", // Increase the size of the icon
                        background: "white",
                        padding: "3px",
                        borderRadius: "10px 0px 10px 0px",
                      }}
                    />
                  </IconButton>
                </CustomTooltip>
              </Box>

              <List>
                {menuItems.map((item, index) => (
                  <ListItem
                    key={index}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        borderRight: "4px solid #004ba1",

                        backgroundColor: "#2e3b4a",
                      }}
                      onClick={() => navigate(item.link)}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                          color: "#9fb4c7",
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.text}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
            {/* Fixed Bottom Section */}
            <Box sx={{ position: "relative" }}>
              <Divider sx={{ backgroundColor: "#555" }} />
              <Box
                sx={{
                  padding: open ? "10px" : "5px",
                  display: "flex",
                  flexDirection: open ? "row" : "column",
                  alignItems: "center",
                  Content: "center",
                  gap: open ? "0px" : "5px",
                  marginLeft: open ? "-20px" : "0px",
                  // marginRight: "30px",
                  ...(isRtl ? { marginRight: "0px" } : { marginRight: "30px" }),

                  bottom: 0,
                  // left: 10,
                  width: open ? 260 : 60, // Match drawer width
                  backgroundColor: "#212930", // Match drawer background
                }}
              >
                <div>
                  <LogOutDropDown />
                </div>
                <div
                  style={{
                    marginRight: open ? (isRtl ? "0" : "30px") : "0",
                    marginLeft: open ? (isRtl ? "20px" : "0") : "0",
                  }}
                >
                  <LanguageSwitcher />
                </div>
                <div>
                  <Feedback />
                </div>
              </Box>
            </Box>
          </Drawer>

          {/* About Us Section */}
          <AboutUsContent>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ color: "#ffffff", fontWeight: "bold" }}
            >
              About
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#9fb4c7",
                marginBottom: "20px",
                lineHeight: 1.6,
                textAlign: "justify",
              }}
            >
              In partnership with iCARE - ADPC, and the Precision Agriculture
              Lab at the Center for Advanced Studies in Agriculture and Food
              Security, University of Agriculture, Faisalabad, the Kisan360 App
              leverages satellite imagery, computer vision, and cutting-edge
              artificial intelligence to revolutionize crop monitoring. Our aim
              is to provide farmers with precise, actionable insights into crop
              health, water levels, and soil conditions. By equipping farmers
              with this vital information, we strive to facilitate informed
              decision-making and enhance agricultural productivity.
            </Typography>
            <Divider sx={{ backgroundColor: "#555", margin: "20px 0" }} />
            <Typography
              variant="h4"
              gutterBottom
              sx={{ color: "#ffffff", fontWeight: "bold" }}
            >
              Contact
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#9fb4c7",
                lineHeight: 1.6,
                textAlign: "justify",
              }}
            >
              Pakistan-China Joint Lab for AI & Smart Agriculture / Precision
              Agriculture Lab, Center for Advanced Studies - Agriculture and
              Food Security (CAS-AFS), University of Agriculture Faisalabad,
              Pakistan.
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "15px",
                zIndex: "100",
                gap: "20px",
              }}
              className="company-logo-about mt-4"
            >
              <img src={Adpc} alt="UAF" width={90} />
              <img src={Uaf} alt="UAF" width={60} className="uaf-logo-about" />
              <img src={Gzis} alt="UAF" width={70}  className="gzis-logo-about"/>
            </div>
          </AboutUsContent>
        </div>
      </ThemeProvider>
    </div>
  );
};

export default AboutUsPage;
