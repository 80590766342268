import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import "../Sign-Up/Signup.css";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import GoogleLogo from "../Assets/Signup-Images/google.png";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LanguageSwitcher from "../Language-Switcher/languageSwitcher";
import { useTranslation } from "react-i18next";
import { Alert } from "react-bootstrap";
import LoginPic3 from "../Assets/Signup-Images/login-image-10.jpg";
import LoginPic2 from "../Assets/Signup-Images/LoginPic2.png";

import {
  Box,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SignUpImg1 from "../Assets/Signup-Images/signup-img-1.jpg";
import SignUpImg2 from "../Assets/Signup-Images/taaj-login.png";
import SignUpImg3 from "../Assets/Signup-Images/signup-img-3.jpg";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import FilledInput from "@mui/material/FilledInput";
import useStore from "../../zustand/store";
import "sweetalert2/dist/sweetalert2.min.css";
import KisanLogo from "../Assets/Logo/kisan-bg.png";
import { useSnackbar } from "notistack";

const LoginSchema = Yup.object().shape({
  phone: Yup.string().required("Phone Number is required"),
  password: Yup.string().required("Password is required"),
});

function Signup({ setOpenModal, openModal, handleClose }) {
  const { t } = useTranslation(); // Ensure this is correctly imported and used
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state
  const [googleLoading, setGoogleLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({
    show: false,
    variant: "",
    message: "",
  });
  const { enqueueSnackbar } = useSnackbar(); // Get enqueueSnackbar function

  // const isRtl = i18n.dir() === "rtl"; // Check if the current language direction is RTL

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate();
  const [City, setCity] = useState("");

  const initialValues = {
    phone: "",
    password: "",
  };

  const handleSubmit = async (values) => {
    const { setLocalUser } = useStore.getState();
    setLoading(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 2500));

      const baseUrl =
        process.env.NODE_ENV === "development"
          ? "http://localhost:8080"
          : "https://api.kisan360.net";

      const response = await axios.post(`${baseUrl}/api/users/login`, values);

      const userData = {
        fullname: response.data.fullname,
        phone: response.data.phone,
      };
      setLocalUser(userData);

      // Override expiresIn for testing (e.g., 10 seconds)
      // const expiresIn = 10; // 10 seconds for testing purposes
      const expiresIn = 60 * 60; // 30 minutes in seconds
      console.log("expiresIn:", expiresIn);

      const expirationTime = Date.now() + Number(expiresIn) * 1000;
      console.log("expirationTime:", expirationTime);

      localStorage.setItem("token", response.data.token);
      localStorage.setItem("isAuthenticated", "true");
      localStorage.setItem("expirationTime", expirationTime);

      // Show success notification with user's display name
      enqueueSnackbar(`Login Successful : Welcome Back, ${userData.fullname}`, {
        variant: "success",
        autoHideDuration: 1500,
      });

      navigate("/");
    } catch (error) {
      // Show error notification
      enqueueSnackbar("Login Failed : Invalid Phone or Password", {
        variant: "error",
        autoHideDuration: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  const loginwithgoogle = () => {
    setGoogleLoading(true); // Set loading state to true

    // Dynamically set the callback URL based on environment
    const baseUrl =
      process.env.NODE_ENV === "development"
        ? "http://localhost:8080"
        : "https://api.kisan360.net";
    const callbackUrl = `${baseUrl}/auth/google/callback`;

    try {
      setTimeout(() => {
        window.open(callbackUrl, "_self");
      }, 2500); // 2-second delay
    } catch (error) {
      console.error("Error during Google login:", error);
      // Handle potential errors here, e.g., show an error message to the user
      setGoogleLoading(false); // Reset loader in case of error
    }
    // Remove the finally block
  };

  // forgotpassword whatsapp define

  const openWhatsApp = () => {
    const phoneNumber = "923247299205"; // Replace with your phone number
    const message = "Forgot Password assistance"; // Replace with your message
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(url, "_blank");
  };

  const customTheme = createTheme({
    palette: {
      primary: {
        main: "#FF0000",
      },
    },
  });

  const signUpNavigate = (e) => {
    e.preventDefault();
    navigate("/signup");
  };

  const handleInput = (event) => {
    const input = event.target;
    input.value = input.value.replace(/[^0-9]/g, "");
  };

  return (
    <div className="main-container">
      <div className="main-login row">
        <div className="bg-img col-lg-6 d-none d-lg-block">
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={SignUpImg2}
                  className="d-block w-100 "
                  alt="..."
                  style={{ height: "100vh" }}
                />
                <div
                  class="carousel-caption d-none d-md-block "
                  style={{
                    backgroundColor: "rgba(50, 55, 13, 0.4)",
                    padding: "15px",

                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    rotate: "inherit",
                    fontFamily: "'Noto Nastaliq Urdu', serif",
                    marginBottom: "15px",
                  }}
                >
                  <h4 style={{ fontWeight: "600" }}>
                    {t("Manage your fields remotely")}
                  </h4>
                  <p
                    style={{
                      fontSize: "18px",
                      marginTop: "30px",
                      fontFamily: " serif",
                    }}
                  >
                    {t(
                      "Monitor the state of your crops right from the office, learn about the slightest changes on-the-spot, and make fast and reliable decisions on field treatment"
                    )}
                  </p>
                </div>
              </div>
              <div className="carousel-item">
                <img
                  src={LoginPic2}
                  className="d-block w-100"
                  alt="..."
                  style={{ height: "100vh" }}
                />
                <div
                  class="carousel-caption d-none d-md-block"
                  style={{
                    backgroundColor: "rgba(50, 55, 13, 0.4)",
                    padding: "15px",

                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    rotate: "inherit",
                    fontFamily: "'Noto Nastaliq Urdu', serif",
                    marginBottom: "15px",
                  }}
                >
                  <h4 style={{ fontWeight: "600" }}>
                    {t("Manage your fields remotely")}
                  </h4>
                  <p
                    style={{
                      fontSize: "18px",
                      marginTop: "30px",
                      fontFamily: " serif",
                    }}
                  >
                    {t(
                      "Monitor the state of your crops right from the office, learn about the slightest changes on-the-spot, and make fast and reliable decisions on field treatment"
                    )}
                  </p>
                </div>
              </div>
              <div className="carousel-item">
                <img
                  src={LoginPic3}
                  className="d-block w-100"
                  alt="..."
                  style={{ height: "100vh" }}
                />
                <div
                  class="carousel-caption d-none d-md-block"
                  style={{
                    backgroundColor: "rgba(50, 55, 13, 0.4)",
                    padding: "15px",

                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    rotate: "inherit",
                    fontFamily: "'Noto Nastaliq Urdu', serif",
                    marginBottom: "15px",
                  }}
                >
                  <h4 style={{ fontWeight: "600" }}>
                    {t("Manage your fields remotely")}
                  </h4>
                  <p
                    style={{
                      fontSize: "18px",
                      marginTop: "30px",
                      fontFamily: " serif",
                    }}
                  >
                    {t(
                      "Monitor the state of your crops right from the office, learn about the slightest changes on-the-spot, and make fast and reliable decisions on field treatment"
                    )}
                  </p>
                </div>
              </div>
            </div>
            <button
              className="carousel-control-prev carousel-control-prev-sm"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>

        <div className="signUp-section col-lg-4  mx-auto ">
          <Formik
            initialValues={initialValues}
            validationSchema={LoginSchema}
            onSubmit={handleSubmit}
          >
            <ThemeProvider theme={customTheme}>
              <div className="signUp-new-Container ">
                <div className="text-center">
                  <div>
                    <img src={KisanLogo} alt="logo" width={120} />
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "center" }}
                  className="mt-4"
                >
                  <button
                    className="btn btn-light"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px", // Adjusted gap
                      width: "100%",
                      justifyContent: "center", // Center the content
                      position: "relative", // Add relative positioning
                    }}
                    onClick={loginwithgoogle}
                    disabled={googleLoading} // Disable button when loading
                  >
                    {googleLoading ? (
                      <>
                        <CircularProgress size={20} sx={{ color: "#004ba1" }} />
                        <span
                          style={{
                            color: "#004ba1", // Loader text color
                            fontWeight: "500",
                          }}
                        >
                          Logging in...
                        </span>
                      </>
                    ) : (
                      <>
                        <img
                          src={GoogleLogo}
                          alt=""
                          style={{
                            width: "24px",
                            height: "24px",
                            position: "absolute",
                            left: "10px",
                          }} // Adjusted position
                        />
                        <span
                          style={{
                            flex: 1,
                            textAlign: "center",
                            color: "#0000008A",
                            fontWeight: "500",
                            // fontFamily: "'Noto Nastaliq Urdu', serif",
                          }}
                        >
                          {t("Continue with Google")}
                        </span>
                      </>
                    )}
                  </button>
                </div>

                <div className="line-with-text">
                  <span className="or-text">Or</span>
                </div>
                <Form>
                  <div className="signup-error  mt-4">
                    <Field
                      name="phone"
                      as={TextField}
                      label={t("Enter Phone Number")}
                      variant="filled"
                      id="filled-basic"
                      size="small"
                      fullWidth
                      InputProps={{ style: { color: "#9fb4c7" } }}
                      InputLabelProps={{ style: { color: "#9fb4c7" } }}
                      onInput={handleInput}
                    />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="all-signup-error"
                    />
                  </div>

                  <div className="signup-error mt-4">
                    <Field name="password">
                      {({ field }) => (
                        <FormControl sx={{ width: "100%" }} variant="filled">
                          <InputLabel
                            htmlFor="filled-adornment-password"
                            style={{ color: "#9fb4c7" }}
                          >
                            Enter Password
                          </InputLabel>
                          <FilledInput
                            {...field}
                            id="filled-adornment-password"
                            type={showPassword ? "text" : "password"}
                            fullWidth
                            size="small"
                            sx={{ color: "#9fb4c7" }}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                  sx={{ color: "#9fb4c7" }}
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      )}
                    </Field>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="all-signup-error"
                    />
                  </div>
                  <Box sx={{ mt: 1, textAlign: "right" }}>
                    <a
                      href="#"
                      onClick={openWhatsApp}
                      style={{
                        color: "#3592fd",
                        textDecoration: "none",
                        fontSize: 12,
                      }}
                    >
                      Forgot Password?
                    </a>
                  </Box>

                  <div className="signUp-checkbox mt-5">
                    <Typography
                      className="login-text text-center"
                      sx={{ fontSize: 12, color: "white" }}
                    >
                      By logging in, I agree to the{" "}
                      <a
                        href="/terms"
                        style={{ color: "#3592fd", textDecoration: "none" }}
                      >
                        Terms of Service
                      </a>{" "}
                      and{" "}
                      <a
                        href="/privacy"
                        style={{ color: "#3592fd", textDecoration: "none" }}
                      >
                        Privacy Policy
                      </a>
                    </Typography>
                  </div>
                  <Box sx={{ mt: 2 }}>
                    <button
                      type="submit"
                      className="btn btn-primary w-100 signup-btn"
                      style={{
                        background: "#004ba1",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      disabled={loading} // Disable button during loading
                    >
                      {loading && (
                        <CircularProgress
                          size={20}
                          sx={{ color: "#ffffff", marginRight: "8px" }} // Adjust margin to space the loader from the text
                        />
                      )}
                      {loading ? "Logging in..." : "Log in"}
                    </button>
                  </Box>
                  <Box sx={{ mt: 2, textAlign: "center" }}>
                    <Typography sx={{ fontSize: 12, color: "white" }}>
                      Don't have an account?{" "}
                      <span
                        style={{
                          color: "#3592fd",
                          textDecoration: "none",
                          cursor: "pointer",
                        }}
                        onClick={signUpNavigate}
                      >
                        Sign up
                      </span>
                    </Typography>
                  </Box>
                </Form>
              </div>
            </ThemeProvider>
          </Formik>
        </div>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginTop: "-4rem",
            zIndex: "100",
          }}
          className="language-switcher-login"
        >
          <LanguageSwitcher />
        </div> */}
      </div>
    </div>
  );
}

export default Signup;
