import React, { useState, useRef, useEffect } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import "leaflet-control-geocoder";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import WrongLocationIcon from "@mui/icons-material/WrongLocation";
import { Tooltip, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./SearchComponent.css";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, isRtl }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "black",
    color: "white",
    fontSize: isRtl ? 15 : 12,
  },
}));

const LocationSearch = () => {
  const map = useMap();
  const [marker, setMarker] = useState(null);
  const { t, i18n } = useTranslation();
  const markerRef = useRef(null);
  const isRtl = i18n.dir() === "rtl";

  useEffect(() => {
    const geocoder = L.Control.Geocoder.nominatim();

    const control = L.Control.geocoder({
      geocoder,
      defaultMarkGeocode: false,
      collapsed: false,
    })
      .on("markgeocode", (e) => {
        const { lat, lng } = e.geocode.center;

        if (markerRef.current) {
          map.removeLayer(markerRef.current);
        }

        const newMarker = L.marker([lat, lng], {
          icon: L.divIcon({
            className: "custom-marker",
            html: `
              <div class="marker-wrapper">
                <i class="fa fa-circle" style="color: #fff; font-size: 24px;"></i>
              </div>
            `,
            iconSize: [24, 24],
          }),
        }).addTo(map);

        newMarker.bindPopup(e.geocode.name).openPopup();

        markerRef.current = newMarker;
        setMarker(newMarker);
        map.setView([lat, lng], 13);
      })
      .addTo(map);

    const searchContainer = control.getContainer();
    const mapContainer = map.getContainer();

    // Create a wrapper for the search bar and buttons
    const searchWrapper = document.createElement("div");
    searchWrapper.style.position = "absolute";
    searchWrapper.style.left = "50%";
    searchWrapper.style.top = "10px";
    searchWrapper.style.transform = "translateX(-50%)";
    searchWrapper.style.zIndex = 700;
    searchWrapper.style.display = "flex";
    searchWrapper.style.alignItems = "center";

    mapContainer.appendChild(searchWrapper);
    searchWrapper.appendChild(searchContainer);

    // Create a div to hold the buttons and append to the wrapper
    const buttonContainer = document.createElement("div");
    buttonContainer.style.display = "flex";
    buttonContainer.style.alignItems = "center";
    searchWrapper.appendChild(buttonContainer);

    const input = searchContainer.querySelector("input");
    const customWrapper = document.createElement("div");
    customWrapper.className = "custom-geocoder-wrapper";
    input.parentNode.insertBefore(customWrapper, input);
    customWrapper.appendChild(input);

    input.placeholder = t("Search");

    return () => {
      map.removeControl(control);
      if (markerRef.current) {
        map.removeLayer(markerRef.current);
      }
      mapContainer.removeChild(searchWrapper);
    };
  }, [map, i18n]);

  useEffect(() => {
    if (!map) {
      console.error("Map instance is not available");
      return;
    }

    const handleLocationFound = (e) => {
      if (markerRef.current) {
        map.removeLayer(markerRef.current);
      }

      const newMarker = L.marker([e.latlng.lat, e.latlng.lng], {
        icon: L.divIcon({
          className: "custom-marker",
          html: `
            <div class="marker-wrapper">
              <i class="fa fa-circle" style="color: #fff; font-size: 24px;"></i>
            </div>
          `,
          iconSize: [24, 24],
        }),
      }).addTo(map);

      if (i18n.dir() === "ltr") {
        const tooltipContent = `
          <div style="position: relative;">
            <span>You are here</span>
          </div>
        `;

        newMarker
          .bindTooltip(tooltipContent, {
            className: "custom-tooltip",
            permanent: true,
            direction: "top",
            opacity: 1,
            offset: [0, -24],
          })
          .openTooltip();
      }

      markerRef.current = newMarker;
      setMarker(newMarker);
      map.flyTo(e.latlng, 16);
    };

    const handleLocationError = () => {
      setMarker(null);
    };

    map.on("locationfound", handleLocationFound);
    map.on("locationerror", handleLocationError);

    return () => {
      map.off("locationfound", handleLocationFound);
      map.off("locationerror", handleLocationError);
    };
  }, [map, i18n.dir()]);

  const locateUser = () => {
    if (!map) {
      console.error("Map instance is not available");
      return;
    }
    map.locate();
  };

  const removeMarker = () => {
    if (markerRef.current) {
      map.removeLayer(markerRef.current);
      markerRef.current = null;
      setMarker(null);
    }
  };

  return (
    <div>
      <div id="search-bar-wrapper">
        {/* Dynamically render buttons within the wrapper */}
        {!marker && (
          <CustomTooltip
            title={t("Locate Me")}
            placement={isRtl ? "left" : "right"}
          >
            <MyLocationIcon
              onClick={locateUser}
              sx={{
                color: "#343a40",
                cursor: "pointer",
                fontSize: "31px",
                backgroundColor: "#f4f4f4",
                borderRadius: "3px",
                padding: "5px",
                lineHeight: "1",
                marginLeft: "10px",
                marginRight: isRtl ? "10px" : "0px",
                marginTop: isRtl ? "95px" : "-39px", // Corrected marginTop
              }}
              className="landmarkremove"
            />
          </CustomTooltip>
        )}
        {marker && (
          <CustomTooltip
            title={t("Remove Landmark")}
            placement={isRtl ? "left" : "right"}
          >
            <WrongLocationIcon
              onClick={removeMarker}
              style={{
                backgroundColor: "#f4f4f4",
                color: "#343a40",
                cursor: "pointer",
                borderRadius: "3px",
                padding: "5px",
                fontSize: "31px",
                marginLeft: "10px",
                marginTop: isRtl ? "95px" : "-39px", // Corrected marginTop
                marginRight: isRtl ? "10px" : "0px",
              }}
              className="landmarkremove"
            />
          </CustomTooltip>
        )}
      </div>
    </div>
  );
};

export default LocationSearch;
