export const predefinedAreas = [
  {
    name: {
      en: "Directorate Farms",
      ur: "ڈائریکٹوریٹ فارمز",
    },
    area: "فیصل آباد",
    contactNumber: "0345-9090909",
    typeOfCrop: "سویا بین",
    totalArea: "42.34 ایکڑ",
    coords: [
      { lat: 31.439863261909211, lng: 73.0726681769668 },
      { lat: 31.443890810076887, lng: 73.07685242303003 },
      { lat: 31.44454984693319, lng: 73.07582245476831 },
      { lat: 31.444385088153666, lng: 73.07421312935938 },
      { lat: 31.44184044354873, lng: 73.0703078330337 },
      { lat: 31.439863261909211, lng: 73.0726681769668 },
    ],
  },
  {
    name: {
      en: "PARS Farms",
      ur: "پارس فارمز",
    },
    area: "فیصل آباد",
    contactNumber: "0301-1234567",
    typeOfCrop: "گندم",
    totalArea: "21.33 ایکڑ",
    texture: "Sandy Loam",
    ph: "7.6",
    EC: "2.23",
    N: "",
    Phospho: "3.47",
    potassium: "50",
    OM: "89.",
    coords: [
      { lat: 31.3887423608153, lng: 73.01820835275822 },
      { lat: 31.39125181237228, lng: 73.02097639246159 },
      { lat: 31.39262557131475, lng: 73.0191954056757 },
      { lat: 31.390024570719575, lng: 73.01644882364445 },
      { lat: 31.3887423608153, lng: 73.01820835275822 },
    ],
  },
  {
    name: {
      en: "Chakera 1",
      ur: "چکیرہ 1",
    },
    area: "فیصل آباد",
    contactNumber: "0310-9876546",
    typeOfCrop: "موڈ گراس",
    totalArea: "11.13 ایکڑ ",
    texture: "Loam",
    ph: "8.4",
    EC: "88.",
    N: "",
    Phospho: "7.35",
    potassium: "110",
    OM: "1",
    coords: [
      { lat: 31.446706199741865, lng: 73.01383954073212 },
      { lat: 31.447406403657887, lng: 73.01463347226583 },
      { lat: 31.44794185291597, lng: 73.01454764391205 },
      { lat: 31.449671739981362, lng: 73.01224094415925 },
      { lat: 31.44868323699818, lng: 73.01122170473359 },
      { lat: 31.446706199741865, lng: 73.01383954073212 },
    ],
  },
  {
    name: {
      en: "Chakera 2",
      ur: "چکیرہ 2",
    },
    area: "فیصل آباد",
    contactNumber: "0310-9876546",
    typeOfCrop: " گنا",
    totalArea: "13.68 ایکڑ ",
    texture: "Loam",
    ph: "8.2",
    EC: "2.75",
    N: "",
    Phospho: "22.5",
    potassium: "440",
    OM: "75.",
    coords: [
      { lat: 31.45072100331773, lng: 73.01059636223863 },
      { lat: 31.452487449000447, lng: 73.0082682048137 },
      { lat: 31.451233550573114, lng: 73.00698074448655 },
      { lat: 31.449448775689767, lng: 73.00920161355089 },
      { lat: 31.45072100331773, lng: 73.01059636223863 },
    ],
  },
];
