import React, { useRef, useEffect } from "react";

const CanvasOverlay = ({ bounds, imageUrl, opacity, aoi }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!imageUrl || !bounds || !aoi.length) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const image = new Image();
    image.src = imageUrl;
    image.onload = () => {
      // Set canvas dimensions based on the container dimensions
      const container = canvas.parentElement;
      const containerWidth = container.offsetWidth;
      const containerHeight = container.offsetHeight;

      canvas.width = containerWidth;
      canvas.height = containerHeight;

      const { north, south, east, west } = bounds;
      const boundsWidth = east - west;
      const boundsHeight = north - south;

      // Clear the canvas before drawing
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.globalAlpha = opacity;

      // Calculate the clipping path based on the AOI polygon
      ctx.beginPath();
      aoi.forEach((point, index) => {
        const x = ((point.lng - west) / boundsWidth) * canvas.width;
        const y = ((north - point.lat) / boundsHeight) * canvas.height;
        if (index === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }
      });
      ctx.closePath();
      ctx.clip();

      // Calculate the bounding box of the AOI
      const xMin = Math.min(
        ...aoi.map(
          (point) => ((point.lng - west) / boundsWidth) * canvas.width
        )
      );
      const xMax = Math.max(
        ...aoi.map(
          (point) => ((point.lng - west) / boundsWidth) * canvas.width
        )
      );
      const yMin = Math.min(
        ...aoi.map(
          (point) => ((north - point.lat) / boundsHeight) * canvas.height
        )
      );
      const yMax = Math.max(
        ...aoi.map(
          (point) => ((north - point.lat) / boundsHeight) * canvas.height
        )
      );

      const aoiWidth = xMax - xMin;
      const aoiHeight = yMax - yMin;

      // Draw the image to fit exactly within the AOI bounding box
      ctx.drawImage(image, xMin, yMin, aoiWidth, aoiHeight);
    };
  }, [imageUrl, bounds, opacity, aoi]);

  return (
    <div
      style={{
        position: "absolute",
        top: 20,
        left: "50%", // Center horizontally
        transform: "translateX(-50%)", // Center horizontally
        width: "20%", // Adjust width as needed
        height: "35%", // Adjust height as needed
        // background: "rgba(255, 255, 255, 0.2)", // Adjust background color and opacity
        background: "#EFF1F7", // Black background with 50% opacity
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.8)", // Add a subtle shadow
        borderRadius: "10px", // Rounded corners
        overflow: "hidden", // Ensure contents don't overflow
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <canvas
        ref={canvasRef}
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
};

export default CanvasOverlay;
