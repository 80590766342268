import React, { useState, useRef, useEffect } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import WrongLocationIcon from "@mui/icons-material/WrongLocation";
import { Tooltip, styled } from "@mui/material";
import { useTranslation } from "react-i18next";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, isRtl }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "black",
    color: "white",
    fontSize: isRtl ? 15 : 12, // Set fontSize based on RTL or LTR
  },
}));

const CurrentLocation = () => {
  const { t, i18n } = useTranslation();
  const [marker, setMarker] = useState(null);
  const [locationFound, setLocationFound] = useState(false);
  const map = useMap();
  const markerRef = useRef(null);
  const isRtl = i18n.dir() === "rtl"; // Check if the current language direction is RTL

  useEffect(() => {
    if (!map) {
      console.error("Map instance is not available");
      return;
    }

    const handleLocationFound = (e) => {
      if (markerRef.current) {
        map.removeLayer(markerRef.current);
      }

      const newMarker = L.marker([e.latlng.lat, e.latlng.lng], {
        icon: L.divIcon({
          className: "custom-marker",
          html: `
            <div class="marker-wrapper">
              <i class="fa fa-circle" style="color: goldenrod; font-size: 24px;"></i>
            </div>
          `,
          iconSize: [24, 24],
        }),
      }).addTo(map);

      if (i18n.dir() === "ltr") {
        // Only show the tooltip if the direction is LTR
        const tooltipContent = `
          <div style="position: relative;">
            <span>You are here</span>
          </div>
        `;

        newMarker
          .bindTooltip(tooltipContent, {
            className: "custom-tooltip",
            permanent: true,
            direction: "top",
            opacity: 1,
            offset: [0, -24], // Adjust the offset to position the tooltip above the marker
          })
          .openTooltip();
      }

      markerRef.current = newMarker;
      setMarker(newMarker);
      setLocationFound(true);
      map.flyTo(e.latlng, 16);
    };

    const handleLocationError = () => {
      setLocationFound(false);
    };

    map.on("locationfound", handleLocationFound);
    map.on("locationerror", handleLocationError);

    map.locate();

    return () => {
      map.off("locationfound", handleLocationFound);
      map.off("locationerror", handleLocationError);
    };
  }, [map, i18n.dir()]);

  const locateUser = () => {
    if (!map) {
      console.error("Map instance is not available");
      return;
    }
    map.locate();
  };

  const removeMarker = () => {
    if (markerRef.current) {
      map.removeLayer(markerRef.current);
      markerRef.current = null;
      setMarker(null);
      setLocationFound(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        height: "100%",
      }}
    >
      {!locationFound && (
        <CustomTooltip
          title={t("Locate Me")}
          placement={i18n.dir() === "rtl" ? "left" : "right"}
        >
          <MyLocationIcon
            onClick={locateUser}
            sx={{
              color: "#343a40",
              cursor: "pointer",
              fontSize: "31px",
              backgroundColor: "#f4f4f4",
              borderRadius: "3px",
              padding: "5px",
              lineHeight: "1",
              marginBottom: "13px",
              marginTop: "135px",
              marginRight: "10px",
            }}
            className="landmarkremove"
          />
        </CustomTooltip>
      )}
      <div>
        {marker && (
          <CustomTooltip
            title={t("Remove Landmark")}
            placement={i18n.dir() === "rtl" ? "left" : "right"}
          >
            <WrongLocationIcon
              onClick={removeMarker}
              style={{
                backgroundColor: "#f4f4f4",
                color: "#343a40",
                cursor: "pointer",
                borderRadius: "3px",
                padding: "5px",
                fontSize: "31px",
                marginTop: "135px",
                marginRight: "10px",
              }}
              className="landmarkremove"
            />
          </CustomTooltip>
        )}
      </div>
    </div>
  );
};

export default CurrentLocation;
