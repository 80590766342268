import React, { useState } from 'react';
import axios from 'axios';

const TextToSpeech = () => {
  const [audioSrc, setAudioSrc] = useState('');

  // New Urdu text to convert to speech
  const urduText = `
  اس کھیت کا ايکڑ رقبہ بہترين حالت ميں ہے اور اسے  گہرے سبز  ميں دکهايا گيا ہے۔
  `;

  // Voice type for Urdu Female or Male
  const voiceType = "urdu_female"; // or "urdu_male"

  const handleButtonClick = async () => {
    try {
      const response = await axios.post('http://localhost:8080/api/synthesize', { text: urduText, voiceType });
      const audioContent = response.data.audioContent;
      const audioSrc = `data:audio/mp3;base64,${audioContent}`;
      setAudioSrc(audioSrc);
    } catch (error) {
      console.error('Error generating speech:', error);
    }
  };

  return (
    <div>
      <button onClick={handleButtonClick}>Play Urdu Text</button>
      {audioSrc && <audio controls src={audioSrc}></audio>}
    </div>
  );
};

export default TextToSpeech;
