import React, { useEffect } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./customZoomControl.css";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Collapse,
  Box,
  Divider,
  Switch,
  Typography,
  styled,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { useTranslation } from "react-i18next";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, isRtl }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "black",
    color: "white",
    fontSize: isRtl ? 14 : 12, // Set fontSize based on RTL or LTR
  },
}));

const CustomZoomControl = () => {
  const { t, i18n } = useTranslation();
  const map = useMap();

  useEffect(() => {
    // Hide the default zoom control
    map.zoomControl.remove();
  }, [map]);

  const handleZoomIn = () => {
    map.zoomIn();
  };

  const handleZoomOut = () => {
    map.zoomOut();
  };

  return (
    <div className="custom-zoom-control">
      <CustomTooltip title={t("Zoom in")}   placement={i18n.dir() === "rtl" ? "left" : "right"}
>
        <button onClick={handleZoomIn} className="inner-zoom-in"> <i class="fa-solid fa-magnifying-glass-plus"></i></button>
      </CustomTooltip>
      <CustomTooltip title={t("Zoom out")}   placement={i18n.dir() === "rtl" ? "left" : "right"}
>
        <button onClick={handleZoomOut} className="inner-zoom-out"> <i class="fa-solid fa-magnifying-glass-minus"></i></button>
      </CustomTooltip>
    </div>
  );
};

export default CustomZoomControl;
