import create from "zustand";
import { persist } from "zustand/middleware";

const useStore = create(
  persist(
    (set) => ({
      localuser: null,
      setLocalUser: (user) => set({ localuser: user }),
      clearLocalUser: () => set({ localuser: null })
    }),
    {
      name: "localuser-storage", // name of the item in the storage (must be unique)
      getStorage: () => localStorage, // (optional) by default the 'localStorage' is used
    }
  )
);

export default useStore;
