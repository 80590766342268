import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const LoginSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    if (token) {
      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('token', token); // Store the token
      navigate('/'); // Redirect to the home page or any other page
    } else {
      navigate('/login'); // Redirect to login if there's no token
    }
  }, [location, navigate]);

  return <div>Loading...</div>;
};

export default LoginSuccess;
