import { create } from 'zustand';

const useStore = create((set) => ({
  aoi: JSON.parse(localStorage.getItem('aoi')) || null, // Initialize from local storage or default to null
  setAoi: (newAoi) => {
    console.log("Setting AOI with coordinates:", newAoi);
    set({ aoi: newAoi });
    localStorage.setItem('aoi', JSON.stringify(newAoi)); // Save to local storage
  },
}));

export default useStore;
