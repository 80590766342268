import React, { useEffect, useRef } from 'react';
import { MapContainer, TileLayer, FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

const MapComponent = () => {
  const mapRef = useRef();

  const handleLoad = (map) => {
    console.log('Map loaded:', map);
    // You can add any additional Leaflet map configurations here
  };

  const drawPolygon = (polygon) => {
    const coordinates = polygon[0].map(latlng => [latlng.lat, latlng.lng]);
    console.log('Polygon coordinates:', coordinates);
    // Save the coordinates to your database or state
  };

  useEffect(() => {
    if (mapRef.current) {
      handleLoad(mapRef.current);
    }
  }, []);

  const handleCreate = (e) => {
    const layer = e.layer;
    const polygon = layer.getLatLngs();
    drawPolygon(polygon);
  };

  const handleDelete = (e) => {
    // Handle deletion logic if needed
  };

  return (
    <MapContainer
      center={[31.3656, 72.9942]}
      zoom={13}
      style={{ height: '100vh', width: '100%' }}
      whenCreated={(mapInstance) => { mapRef.current = mapInstance }}
    >
      <TileLayer
        url="https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYWxpcmF6YTEyIiwiYSI6ImNseTl3c3B1aTB1bWIycnNiMHRvb2d4cTMifQ.oaLmohjpFkaCiEDxd7idUg"
        attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a>'
      />
      <FeatureGroup>
        <EditControl
          position="topright"
          onCreated={handleCreate}
          onDeleted={handleDelete}
          draw={{
            rectangle: true,
            circle: false,
            circlemarker: false,
            marker: false,
            polyline: false
          }}
        />
      </FeatureGroup>
    </MapContainer>
  );
};

export default MapComponent;
