// store.js
import create from 'zustand';

const usePolygonStore = create((set) => ({
  polygons: [],
  setPolygons: (polygons) => set({ polygons }),
  selectedPolygon: null,
  setSelectedPolygon: (polygon) => set({ selectedPolygon: polygon }),
}));

export default usePolygonStore;
