// src/components/WeatherWidgets.js
import React, { useEffect } from "react";

const WeatherWidgets = () => {
  useEffect(() => {
    const script1 = document.createElement("script");
    script1.src =
      "//openweathermap.org/themes/openweathermap/assets/vendor/owm/js/d3.min.js";
    script1.async = true;
    document.body.appendChild(script1);

    const script2 = document.createElement("script");
    script2.innerHTML = `
      window.myWidgetParam ? window.myWidgetParam : window.myWidgetParam = [];
      window.myWidgetParam.push({
        id: 1,
        cityid: '1179400',
        appid: '4a1da579331826fb650783766abc4ec2',
        units: 'metric',
        containerid: 'openweathermap-widget-1',
      });
      (function() {
        var script = document.createElement('script');
        script.async = true;
        script.charset = 'utf-8';
        script.src = '//openweathermap.org/themes/openweathermap/assets/vendor/owm/js/weather-widget-generator.js';
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(script, s);
      })();
    `;
    document.body.appendChild(script2);
  }, []);

  return (
    <div style={{ display: "flex"}}>
      <div id="openweathermap-widget-1"></div>
    </div>
  );
};

export default WeatherWidgets;
