import * as React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MoreVert from "@mui/icons-material/MoreVert";
import { jwtDecode } from "jwt-decode"; // Correct the import statement
import useStore from "../../zustand/store";
import MapLoader from "../All-Loader/mapLoader"; // Adjust the import path as necessary
import { ListItemText, Tooltip, Collapse, Switch, styled } from "@mui/material";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, isRtl }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "black",
    color: "white",
    fontSize: isRtl ? 14 : 12, // Set fontSize based on RTL or LTR
  },
}));

export default function PrimarySearchAppBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [user, setUser] = React.useState(null); // State for user information
  const [isLoading, setIsLoading] = React.useState(false); // State for loading
  const { localuser } = useStore();

  const { i18n } = useTranslation();

  const isRtl = i18n.dir() === "rtl";

  const isMenuOpen = Boolean(anchorEl);

  const navigate = useNavigate();
  const { t } = useTranslation();

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedUser = jwtDecode(token); // Decode the token
      setUser(decodedUser); // Set user information
    }
  }, []);

  const handleLogout = () => {
    setIsLoading(true); // Show loader

    localStorage.clear(); // Clear the entire local storage
    localStorage.removeItem("imageCount");
    localStorage.removeItem("imageDates");
    localStorage.removeItem('aoi');
    // localStorage.removeItem("ndviThumbUrls");
    // localStorage.removeItem("ndviGradientThumbUrls");
    // localStorage.removeItem("ndmiGradientThumbUrls");
    // localStorage.removeItem("ndmiThumbUrls");
    // localStorage.removeItem("trueColorThumbUrls");
    // localStorage.removeItem("ndviAreas");
    // localStorage.removeItem("ndmiAreas");

    setTimeout(() => {
      setIsLoading(false); // Hide loader after delay
      navigate("/login"); // Navigate to login page
    }, 2500); // Adjust delay as needed
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      sx={{
        "& .MuiPaper-root": { color: "white", background: "black" },
      }}
    >
      {isLoading ? (
        <Box sx={{ padding: 2 }}>
          <MapLoader />
        </Box>
      ) : (
        <>
          <MenuItem onClick={handleMenuClose} sx={{ fontWeight: "bold" }}>
            {user && user.displayName
              ? user.displayName
              : localuser
              ? localuser.fullname
              : "User"}
          </MenuItem>
          {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem> */}
          <MenuItem onClick={handleLogout}>{t("Logout")}</MenuItem>
        </>
      )}
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Toolbar>
        <CustomTooltip title={t("My Account")}   placement={i18n.dir() === "rtl" ? "left" : "right"}
        >
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            sx={{ color: "white" }}
          >
            {user && user.image ? (
              <img
                src={user.image}
                alt={user.displayName}
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: "50%",
                }}
              />
            ) : (
              <AccountCircle sx={{ fontSize: 30 }} />
            )}
          </IconButton>
        </CustomTooltip>
      </Toolbar>
      {renderMenu}
    </Box>
  );
}
