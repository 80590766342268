import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Weather.css';

const Weather = () => {
  const [forecastData, setForecastData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const city = 'Faisalabad';
  const apiKey = '4a1da579331826fb650783766abc4ec2';

  useEffect(() => {
    const fetchWeather = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          `https://api.openweathermap.org/data/2.5/forecast?q=${city}&appid=${apiKey}&units=metric`
        );
        setForecastData(response.data.list);
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    };

    fetchWeather();
  }, [city]);

  const filterForecasts = (data) => {
    const filtered = {};
    const targetTimes = ['00:00:00', '06:00:00', '12:00:00', '18:00:00'];

    data.forEach((item) => {
      const date = item.dt_txt.split(' ')[0];
      const time = item.dt_txt.split(' ')[1];
      if (!filtered[date]) {
        filtered[date] = {};
      }
      if (targetTimes.includes(time)) {
        filtered[date][time] = item;
      }
    });

    // Ensure each date has all target times, if not add placeholders
    Object.keys(filtered).forEach((date) => {
      targetTimes.forEach((time) => {
        if (!filtered[date][time]) {
          filtered[date][time] = null;
        }
      });
    });

    return filtered;
  };

  const renderWeatherByDate = () => {
    const filteredData = filterForecasts(forecastData);
    return Object.keys(filteredData).map((date) => (
      <React.Fragment key={date}>
        <tr className="weather-date">
          <td colSpan="6">{date}</td>
        </tr>
        {Object.keys(filteredData[date]).map((time) => {
          const item = filteredData[date][time];
          return item ? (
            <tr key={item.dt}>
              <td>{time}</td>
              <td>{item.main.temp}°C</td>
              <td>{item.main.temp_max}°C</td>
              <td>{item.main.temp_min}°C</td>
              <td>{item.pop * 100}%</td>
              <td>{item.weather[0].description}</td>
            </tr>
          ) : (
            <tr key={time}>
              <td>{time}</td>
              <td colSpan="5">اس وقت کے لیے کوئی ڈیٹا دستیاب نہیں ہے</td>
              {/* <td colSpan="5">Data not available</td> */}
            </tr>
          );
        })}
      </React.Fragment>
    ));
  };

  return (
    <div className="weather-container">
      {loading && <p>Loading...</p>}
      {error && <p>Error fetching data: {error.message}</p>}
      {forecastData.length > 0 && (
        <table className="weather-table">
          <thead>
            <tr>
              <th>وقت</th>
              <th>درجہ حرارت (°C)</th>
              <th>زیادہ سے زیادہ درجہ حرارت (°C)</th>
              <th>کم سے کم درجہ حرارت (°C)</th>
              <th>بارش کا امکان (%)</th>
              <th>موسم</th>
            </tr>
          </thead>
          <tbody>
            {renderWeatherByDate()}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Weather;
