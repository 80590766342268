import React, { useEffect, useState } from "react";
import axios from "axios";
import "./davisVantageWidget.css";
import FaisalabadForcastWeahter from "../burewalaForecast";
import { ThemeProvider, createTheme, useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import LogOutDropDown from "../../LogOut/logOutDropDown";
import { useNavigate, useLocation, Link } from "react-router-dom";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import MapLoader from "../../All-Loader/mapLoader";
import KisanLogo from "../../Assets/Logo/kisan-bg.png";

import {
  ArrowBackIos as ArrowBackIosIcon,
  Menu as MenuIcon,
  Home as HomeIcon,
  Info as InfoIcon,
  ContactMail as ContactMailIcon,
  Settings as SettingsIcon,
  Person as PersonIcon,
  Dashboard as DashboardIcon,
} from "@mui/icons-material";

import {
  Drawer,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  Divider,
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material";
import LanguageSwitcher from "../../Language-Switcher/languageSwitcher";
import Feedback from "../../Feedback/feedback";
import { Tooltip, styled } from "@mui/material";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, isRtl }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "black",
    color: "white",
    fontSize: isRtl ? 15 : 12, // Set fontSize based on RTL or LTR
  },
}));

const WeatherWidget = () => {
  const [weatherData, setWeatherData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t, i18n } = useTranslation();
  const isRtl = i18n.dir() === "rtl";
  const navigate = useNavigate();
  const location = useLocation(); // Get the current path

  useEffect(() => {
    const fetchWeather = async () => {
      setLoading(true);
      setError(null);

      // Simulate a delay before fetching data
      setTimeout(async () => {
        try {
          const response = await axios.get(
            "https://cropmet.paluaf.com/api/getweather/2/Im0MidCW1sHSrn0n7O9fSSRlqJSXMuXk"
          );
          setWeatherData(response.data[0]);
        } catch (err) {
          setError(err);
        }
        setLoading(false);
      }, 2500); // 2-second delay
    };

    fetchWeather();
  }, []);

  const menuItems = [
    {
      text: "Weather Forecast",
      icon: <WbSunnyIcon />,
      link: "/WeatherForecast",
    },
    { text: "CAS Faisalabad", icon: <WbSunnyIcon />, link: "/CASFaisalabad" },
    { text: "Burewala", icon: <WbSunnyIcon />, link: "/BurewalaWeather" },
    { text: "Okara", icon: <WbSunnyIcon />, link: "/OkaraWeather" },
    { text: "Toba Campus", icon: <WbSunnyIcon />, link: "/TobaCampusWeather" },
    // {
    //   text: "Faisalabad 2",
    //   icon: <WbSunnyIcon />,
    //   link: "/DevisVantageWeather",
    // },
  ];
  const darkModeTheme = createTheme({
    palette: {
      mode: "dark",
      background: {
        default: "#212930",
      },
      text: {
        primary: "#9fb4c7",
      },
    },
  });

  //// SideDrawer polygone
  const theme = useTheme(); // Access the theme object
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg")); // Use the theme object with useMediaQuery
  const [open, setOpen] = useState(isLargeScreen);

  useEffect(() => {
    setOpen(isLargeScreen);
  }, [isLargeScreen]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(null);

  const handleMenuOpen = (event, id) => {
    setAnchorEl(event.currentTarget);
    setDropdownVisible(id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setDropdownVisible(null);
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
    setDropdownOpen(false);
  };

  if (loading) return <MapLoader />;
  if (error) return <p>Error fetching data: {error.message}</p>;

  const handleGoBack = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      navigate("/"); // Navigate to the home page
    }, 2500); // Set a delay before navigation
  };
  
  return (
    <div style={{ background: "#212930" }}>
      <ThemeProvider theme={darkModeTheme}>
        <div style={{ display: "flex", height: "100vh" }}>
          <Drawer
            variant="permanent"
            open={open}
            PaperProps={{
              sx: {
                backgroundColor: "#212930",
                color: "#9fb4c7",
                width: open ? 250 : 60, // Increased drawer width
                transition: "width 0.3s",
                overflowX: "hidden",
                position: "relative",
                display: "flex",
                flexDirection: "column",
              },
            }}
          >
            {/* Scrollable Content */}
            <Box
              sx={{
                flexGrow: 1,
                overflowY: "auto",
                overflowX: "hidden",
                maxHeight: "calc(100vh )",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px",
                  justifyContent: open ? "space-between" : "center",
                }}
              >
                <IconButton
                  onClick={handleDrawerToggle}
                  sx={{ color: "#9fb4c7" }}
                >
                  {open ? <ArrowBackIosIcon /> : <MenuIcon />}
                </IconButton>
                {open && <img src={KisanLogo} alt="logo" width={120} />}
              </Box>
              <Divider sx={{ backgroundColor: "#555" }} />


                 {/* Home Icon */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  padding: "8px",
                  justifyContent: "center", // Center the icon horizontally
                  height: "60px", // Set a fixed height for the box
                  backgroundColor: "transparent",
                  "&:hover": {
                    // backgroundColor: "#2a3b4e",
                  },
                }}
                onClick={handleGoBack} // Navigate to home page
              >
                 <CustomTooltip title="Back to Home" placement="right">
                <IconButton>
                  <HomeIcon
                    sx={{
                      color: "#004ba1", // Orange color for the icon
                      fontSize: "2rem", // Increase the size of the icon
                      background:"white",
                      padding:"3px",
                      borderRadius:"10px 0px 10px 0px"
                    }}
                  />
                </IconButton>
                </CustomTooltip>
              </Box>


              {/* Custom Content */}
              <List>
                {menuItems.map((item, index) => (
                  <ListItem
                    key={index}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <CustomTooltip title={item.text} placement="right">
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                          borderRight:
                            location.pathname === item.link
                              ? "4px solid #004ba1"
                              : "none", // Add borderRight when active
                          backgroundColor:
                            location.pathname === item.link
                              ? "#2e3b4a"
                              : "transparent", // Optional: Highlight active item
                        }}
                        onClick={() => navigate(item.link)}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            minHeight: open ? "0px" : "50px",
                            color: "#9fb4c7",
                            alignItems: "center",
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={item.text}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </CustomTooltip>
                  </ListItem>
                ))}
              </List>
            </Box>

            {/* Fixed Bottom Section */}
            <Box sx={{ position: "relative" }}>
              <Divider sx={{ backgroundColor: "#555" }} />
              <Box
                sx={{
                  padding: open ? "10px" : "5px",
                  display: "flex",
                  flexDirection: open ? "row" : "column",
                  alignItems: "center",
                  Content: "center",
                  gap: open ? "0px" : "5px",
                  marginLeft: open ? "-20px" : "0px",
                  // marginRight: "30px",
                  ...(isRtl ? { marginRight: "0px" } : { marginRight: "30px" }),

                  bottom: 0,
                  // left: 10,
                  width: open ? 260 : 60, // Match drawer width
                  backgroundColor: "#212930", // Match drawer background
                }}
              >
                <div>
                  <LogOutDropDown />
                </div>
                <div
                  style={{
                    marginRight: open ? (isRtl ? "0" : "30px") : "0",
                    marginLeft: open ? (isRtl ? "20px" : "0") : "0",
                  }}
                >
                  <LanguageSwitcher />
                </div>
                <div>
                  <Feedback />
                </div>
              </Box>
            </Box>
          </Drawer>

          {/* Cas Faisalabad weather widget start */}

          <div className="w-100 text-center d-flex">
            {/* <Link to="/" className="go-back-button-live">
              <CustomTooltip title="Go Back" placement="right">
                <HomeIcon style={{ color: "#fff", fontSize: "1.5rem" }} />
              </CustomTooltip>
            </Link> */}
            <div className="custom-weather-widget">
              {weatherData && (
                <>
                  <div className="custom-header">
                    <h1 className="city-name">Burewala</h1>
                  </div>
                  <div className="custom-main">
                    <div className="custom-temperature">
                      <span>{weatherData.temperature}°C</span>
                    </div>
                  </div>
                  <div className="custom-details">
                    <div className="detail-item">
                      <i className="fas fa-tint"></i>
                      <span>Humidity: {weatherData.humidity}%</span>
                    </div>
                    <div className="detail-item">
                      <i className="fas fa-cloud-rain"></i>
                      <span>Precipitation: {weatherData.precipitation} mm</span>
                    </div>
                    <div className="detail-item">
                      <i className="fas fa-sun"></i>
                      <span>
                        Solar Radiation: {weatherData.solar_radiation} W/m²
                      </span>
                    </div>
                    <div className="detail-item">
                      <i className="fas fa-tachometer-alt"></i>
                      <span>Pressure: {weatherData.pressure} mb</span>
                    </div>
                    <div className="detail-item">
                      <i className="fas fa-calendar-day"></i>
                      <span>
                        {weatherData.date_time} ({weatherData.day})
                      </span>
                    </div>
                    <div className="custom-wind">
                      <i className="fas fa-wind"></i>
                      <span>{weatherData.wind_speed} km/h</span>
                      <span>
                        {weatherData.wind_direction} (
                        {weatherData.wind_direction_angle}°)
                      </span>
                    </div>
                  </div>
                  <div>
                    <FaisalabadForcastWeahter />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </ThemeProvider>
    </div>
  );
};

export default WeatherWidget;
