import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ShareIcon from "@mui/icons-material/Share";
import DownloadIcon from "@mui/icons-material/Download";

import {
  IconButton,
  useMediaQuery,
  Modal,
  Box,
  Tooltip,
  Button,
  styled,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import "./legendNdvi.css";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, isRtl }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "black",
    color: "white",
    fontSize: isRtl ? 15 : 12,
  },
}));

const Legend = ({ ndviAreas }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const [isOpen, setIsOpen] = useState(isLgUp);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [audioSrc, setAudioSrc] = useState(null);
  const isRtl = i18n.dir() === "rtl";
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsOpen(isLgUp);
  }, [isLgUp]);

  const toggleLegend = () => {
    setIsOpen((prevOpen) => !prevOpen);
  };

  const openModal = async () => {
    setLoading(true); // Start loading

    if (audioSrc) {
      // Stop the current audio if playing
      const audioElement = document.getElementById("audioPlayer");
      if (audioElement) {
        audioElement.pause(); // Pause the current audio
        audioElement.currentTime = 0; // Reset playback time
      }
      // Revoke the previous blob URL to free up memory
      window.URL.revokeObjectURL(audioSrc);
      setAudioSrc(null);
    }

    setTimeout(async () => {
      const totalAcreage = Math.round(
        (ndviAreas?.ndvi4Area || 0) +
          (ndviAreas?.ndvi3Area || 0) +
          (ndviAreas?.ndvi2Area || 0) +
          (ndviAreas?.ndvi1Area || 0)
      );

      const ndvi4Text = ndviAreas?.Category4_Area_acres
        ? `${ndviAreas.Category4_Area_acres} ايکڑ رقبہ بہترين حالت ميں ہے اور اسے گہرے سبز رنگ ميں دکھایا گيا ہے۔`
        : "";

      const ndvi3Text = ndviAreas?.Category3_Area_acres
        ? `${ndviAreas.Category3_Area_acres} ايکڑ رقبہ اچھی حالت ميں ہے اور اسے ہلکے سبز رنگ ميں دکھایا گيا ہے۔`
        : "";

      const ndvi2Text = ndviAreas?.Category2_Area_acres
        ? `${ndviAreas.Category2_Area_acres} ايکڑ رقبہ متوسط حالت ميں ہے اور اسے نارن جی رنگ ميں دکھایا گيا ہے۔`
        : "";

      const ndvi1Text = ndviAreas?.Category1_Area_acres
        ? `${ndviAreas.Category1_Area_acres} ايکڑ رقبہ خراب حالت ميں ہے جسے سرخ رنگ ميں دکھایا گيا ہے اور فصل کے اس حصے کو خصوصی توجہ کی ضرورت ہے۔`
        : "";

      const text = `
        معزز کسان، آپ کی فصل کی مجموعی صحت کا تجزیہ پیش خدمت ہے۔
        
        ${ndvi4Text}
        ${ndvi3Text}
        ${ndvi2Text}
        ${ndvi1Text}
        
        اس رپورٹ سے متعلق مزید رہنمائی کے لئے محکمہ زراعت توسیع کے متعلقہ ایگریکلچر افسر سے رابطہ کریں شکریہ۔
      `;

      try {
        const baseUrl =
          process.env.NODE_ENV === "development"
            ? "http://localhost:8080"
            : "https://api.kisan360.net"; // dynamically set the base URL

        const response = await fetch(`${baseUrl}/api/synthesize`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ text }),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch audio.");
        }

        const blob = await response.blob(); // Get the binary data (Blob)
        const audioUrl = window.URL.createObjectURL(blob); // Create a URL for the blob

        setAudioSrc(audioUrl); // Set the blob URL for the audio player
        setIsModalOpen(true);

        // Automatically play the new audio
        const newAudioElement = document.getElementById("audioPlayer");
        if (newAudioElement) {
          newAudioElement.src = audioUrl;
          newAudioElement.play(); // Play the new audio
        }
      } catch (err) {
        console.error("Error fetching the audio:", err);
      } finally {
        setLoading(false); // End loading after audio is ready
      }
    }, 2500); // 2.5-second delay
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setAudioSrc(null);
  };

  const legendContainerStyle = {
    width: isOpen ? (isMdDown ? "230px" : "300px") : isMdDown ? "30px" : "35px",
    backgroundColor: "#212930",
    padding: isOpen ? "8px" : "16px 5px",
    boxSizing: "border-box",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
    transition: "width 0.5s ease",
    color: "white",
    height: isOpen ? "" : "0px",
    marginTop: "180px",
    zIndex: "-1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    direction: isRtl ? "rtl" : "ltr",
  };

  const toggleArrowStyle = {
    position: "absolute",
    top: "50%",
    [isRtl ? "left" : "right"]: "-20px",
    color: "white",
    transform: "translateY(-50%)",
    backgroundColor: "#004ba1",
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)",
  };

  const itemContainerStyle = {
    display: "flex",
    alignItems: "center",
    margin: "5px 0",
  };

  const colorBoxStyle = (color) => ({
    backgroundColor: color,
    width: "20px",
    height: "20px",
    marginRight: isRtl ? "0" : "10px",
    marginLeft: isRtl ? "10px" : "0",
  });

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white", // Light background color
    padding: "25px",
    borderRadius: "10px",
    boxShadow: "0 0 15px rgba(0, 0, 0, 0.3)", // Light shadow for subtle effect
    outline: "none",
    maxWidth: "400px",
    width: "60%",
    textAlign: "center",
  };

  const closeButtonStyle = {
    position: "absolute",
    top: "0px",
    right: "0px",
    color: "black",
  };

  const downloadButtonStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "186px",
    height: "36px",
    backgroundColor: "#333", // Dark color
    color: "#fff", // White text
    fontWeight: "bold",
    textTransform: "none", // No uppercase transformation
  };

  const handleDownloadClick = () => {
    setIsLoading(true);

    // Simulate a delay (e.g., for fetching the file or processing)
    setTimeout(() => {
      // Trigger the download after the delay
      const link = document.createElement("a");
      link.href = audioSrc;
      link.download = "Crop_Health_Report.mp3";
      link.click();

      setIsLoading(false); // Stop loading after download starts
    }, 2000); // Adjust the delay time as needed
  };

  // const shareAudioOnWhatsApp = () => {
  //   if (audioSrc) {
  //     // Prepare the share message with the audio URL
  //     const textMessage = encodeURIComponent(
  //       `Here's the audio report of your crop health. You can listen to it by clicking the following link: ${audioSrc}`
  //     );
  //     const whatsappUrl = `https://api.whatsapp.com/send?text=${textMessage}`;

  //     // Open WhatsApp share
  //     window.open(whatsappUrl, "_blank");
  //   }
  // };

  const totalAcreage = Math.round(
    (ndviAreas?.Category4_Area_acres || 0) +
      (ndviAreas?.Category3_Area_acres || 0) +
      (ndviAreas?.Category2_Area_acres || 0) +
      (ndviAreas?.Category1_Area_acres || 0)
  );

  const items = [
    {
      color: "#008000",
      label: `${t("Excellent Condition")}: ${
        ndviAreas?.Category4_Area_acres || "N/A"
      }  ${t("Acres")}`,
    },
    {
      color: "#00FF00",
      label: `${t("Good Condition")}: ${
        ndviAreas?.Category3_Area_acres || "N/A"
      }  ${t("Acres")}`,
    },
    {
      color: "#FFA500",
      label: `${t("Average Condition")}: ${
        ndviAreas?.Category2_Area_acres || "N/A"
      }  ${t("Acres")}`,
    },
    {
      color: "#FF0000",
      label: `${t("Poor Condition")}: ${
        ndviAreas?.Category1_Area_acres || "N/A"
      }  ${t("Acres")}`,
    },
  ];

  return (
    <div className="legend-container" style={legendContainerStyle}>
      <CustomTooltip
        title={t("Legend NDVI")}
        placement={i18n.dir() === "rtl" ? "left" : "right"}
      >
        <IconButton
          onClick={toggleLegend}
          className="toggle-arrow"
          style={toggleArrowStyle}
        >
          {isOpen ? (
            isRtl ? (
              <ArrowForwardIosIcon />
            ) : (
              <ArrowBackIosIcon />
            )
          ) : isRtl ? (
            <ArrowBackIosIcon />
          ) : (
            <ArrowForwardIosIcon />
          )}
        </IconButton>
      </CustomTooltip>

      {isOpen && (
        <div className="legend-content" style={{ overflow: "hidden" }}>
          <div
            style={{
              fontWeight: "bold",
              fontSize: "18px",
              margin: "10px 0",
              borderBottom: "1px solid black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "15px",
            }}
          >
            {t("Overall Crop Health Status")}
            <IconButton onClick={openModal}>
              <CustomTooltip
                title={t("Audio Report")}
                placement={i18n.dir() === "rtl" ? "left" : "right"}
              >
                {loading ? (
                  <CircularProgress
                    style={{ color: "white", width: "24px", height: "24px" }}
                  />
                ) : (
                  <VolumeUpIcon style={{ color: "white" }} />
                )}
              </CustomTooltip>
            </IconButton>
          </div>
          {items.map((item, index) => (
            <Tooltip key={index} title={item.tooltip || ""}>
              <div style={itemContainerStyle} className="gap-2">
                <div style={colorBoxStyle(item.color)}></div>
                <span>{item.label}</span>
              </div>
            </Tooltip>
          ))}

          <div
            style={{
              fontWeight: "bold",
              fontSize: "16px",
              // margin: "10px 0",
              display: "flex",
              justifyContent: "start",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            {t("Total Area")} : {totalAcreage} {t("Acres")}
          </div>

          {/* {audioSrc && (
            <div>
              <audio
                controls
                autoPlay
                style={{
                  width: "100%",
                  padding: "10px",
                  borderTop: "1px solid black",
                  paddingTop: "15px",
                }}
              >
                <source src={audioSrc} type="audio/mp3" />
                Your browser does not support the audio element.
              </audio>
            </div>
          )} */}
        </div>
      )}

      {audioSrc && (
        <div>
          <audio
            controls
            autoPlay
            style={{
              width: isOpen
                ? isMdDown
                  ? "230px"
                  : "300px"
                : isMdDown
                ? "0px"
                : "0px",
              padding: isOpen ? "10px" : "0",
              borderTop: isOpen ? "1px solid black" : "none",
              paddingTop: isOpen ? "15px" : "0",
              visibility: isOpen ? "visible" : "visible", // Always visible
            }}
          >
            <source src={audioSrc} type="audio/mp3" />
            Your browser does not support the audio element.
          </audio>
        </div>
      )}

      {/* <Modal
        open={isModalOpen}
        onClose={closeModal}
        BackdropProps={{ invisible: true }} // This disables the dimming effect
      >
        <Box style={modalStyle}>
          <IconButton style={closeButtonStyle} onClick={closeModal}>
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h5"
            style={{
              color: "black",
              marginBottom: "10px",
              display: "flex",
              justifyContent: "center",
              fontWeight: "600",
            }}
          >
            {t("Audio Report")}
          </Typography>
          {audioSrc && (
            <div>
              <audio controls autoPlay style={{ width: "100%" }}>
                <source src={audioSrc} type="audio/mp3" />
                Your browser does not support the audio element.
              </audio>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Button
              variant="contained"
              style={downloadButtonStyle}
              onClick={handleDownloadClick}
              disabled={isLoading}
              className="text-center d-flex"
            >
              {isLoading ? (
                <>
                  <CircularProgress
                    size={24}
                    style={{ color: "#fff", marginRight: 10 }}
                  />
                  {t("Downloading...")}
                </>
              ) : (
                <>
                  <DownloadIcon style={{ marginRight: 8 }} />
                  {t("Download Report")}
                </>
              )}
            </Button>
          </div>
        </Box>
      </Modal> */}
    </div>
  );
};

export default Legend;
