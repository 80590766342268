import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./reportpage.css";

import { useTranslation } from "react-i18next";
import useStore from "../../zustand/store";
import { jwtDecode } from "jwt-decode"; // Correct the import statement

const ReportPage = () => {
  const location = useLocation();
  const [reportData, setReportData] = useState(null);
  const { t, i18n } = useTranslation();
  const [user, setUser] = React.useState(null); // State for user information

  const { localuser } = useStore();

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedUser = jwtDecode(token); // Decode the token
      setUser(decodedUser); // Set user information
    }
  }, []);

  useEffect(() => {
    // Retrieve the data from local storage
    const data = JSON.parse(localStorage.getItem("reportData"));
    setReportData(data);
  }, []);

  if (!reportData) {
    return <div>Loading report...</div>;
  }

  const {
    screenshotUrl,
    // areaDetails,
    reportDate,
    ndviUrl,
    ndviAreas,
    ndmiAreas,
    ndmiUrl,
    ndviGradientUrl,
    ndmiGradientUrl,
    viewType,
    selectedFarmer,
  } = reportData;

  console.log("screenshotUrlaadasd", screenshotUrl);

  return (
    <div className="grand-father mx-auto print-page-width">
      <div className="new-page-content">
        <div className="heading-container text-center mt-5 mb-3">
          <h1> فصل کی صحت کا تجزيہ </h1>
        </div>

        <div className="subtittle-r2r2 mt-5 text-center">
          <p>
            فصل کی صحت کا اشاریہ (NDVI)فصلوں کی صحت کی نشاندہی کرتا ہے۔ یہ
            اشاریہ مختلف رنگوں میں ظاہر کیا جاتا ہے، جس سے پتہ چلتا ہے کہ کونسی
            جگہ پر فصل کی حالت کیسی ہے۔{" "}
          </p>
        </div>
        <div className="heading-r4 mt-3  mt-5">
          <p>حاليہ فصل کی صحت کا تجزيہ :</p>
        </div>

        {/* Ndvi area value start */}

        <div className="subtittle-r4r1 mt-3">
          <p
            style={{
              fontFamily: "sans-serif",
              fontSize: "20px",
              // fontWeight: "bold",
            }}
          >
            {" "}
            اس کهيت کا{" "}
            <span
              style={{
                color: "black",
                fontSize: "22px",
                borderBottom: "1px solid black",
                padding: "5px",
                fontWeight: "bold",
              }}
            >
              {ndviAreas.ndvi4Area}
            </span>{" "}
            ايکڑ رقبہ بہترين حالت ميں ہے اور اسے{" "}
            <span
              style={{
                color: "black",
                fontSize: "22px",
                borderBottom: "1px solid black",
                padding: "5px",
                fontWeight: "bold",
              }}
            >
              گہرے سبز
            </span>{" "}
            ميں دکهايا گيا ہے۔{" "}
          </p>
          <p
            style={{
              fontFamily: "sans-serif",
              fontSize: "20px",
              // fontWeight: "bold",
            }}
          >
            {" "}
            اس کهيت کا{" "}
            <span
              style={{
                color: "black",
                fontSize: "22px",
                borderBottom: "1px solid black",
                padding: "5px",
                fontWeight: "bold",
              }}
            >
              {ndviAreas.ndvi3Area}
            </span>{" "}
            ايکڑ رقبہ اچهی حالت ميں ہے اور اسے{" "}
            <span
              style={{
                color: "black",
                fontSize: "22px",
                borderBottom: "1px solid black",
                padding: "5px",
                fontWeight: "bold",
              }}
            >
              {" "}
              ہلکے سبز{" "}
            </span>{" "}
            ميں دکهايا گيا ہے۔{" "}
          </p>
          <p
            style={{
              fontFamily: "sans-serif",
              fontSize: "20px",
              // fontWeight: "bold",
            }}
          >
            {" "}
            اس کهيت کا
            <span
              style={{
                color: "black",
                fontSize: "22px",
                borderBottom: "1px solid black",
                padding: "5px",
                fontWeight: "bold",
              }}
            >
              {ndviAreas.ndvi2Area}
            </span>{" "}
            ايکڑ رقبہ متوسط حالت ميں ہے اور اسے{" "}
            <span
              style={{
                color: "black",
                fontSize: "22px",
                borderBottom: "1px solid black",
                padding: "5px",
                fontWeight: "bold",
              }}
            >
              {" "}
              نارنجی{" "}
            </span>{" "}
            ميں دکهايا گيا ہے۔{" "}
          </p>
          <p
            style={{
              fontFamily: "sans-serif",
              fontSize: "20px",
              // fontWeight: "bold",
            }}
          >
            {" "}
            اس کهيت کا{" "}
            <span
              style={{
                color: "black",
                fontSize: "22px",
                borderBottom: "1px solid black",
                padding: "5px",
                fontWeight: "bold",
              }}
            >
              {ndviAreas.ndvi1Area}
            </span>{" "}
            ايکڑ رقبہ خراب حالت ميں ہے اور اسے{" "}
            <span
              style={{
                color: "black",
                fontSize: "22px",
                borderBottom: "1px solid black",
                padding: "5px",
                fontWeight: "bold",
              }}
            >
              سرخ
            </span>{" "}
            ميں دکهايا گيا ہے۔{" "}
          </p>
        </div>
        {/* Ndvi area value end */}
      </div>

      {/* // fifth pdf page Content */}

      <div>
        <h3>audio report</h3>
      </div>
    </div>
  );
};

export default ReportPage;
