// src/components/LogoutButton.js
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white, // Arrow color white
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white, // Tooltip background white
    color: theme.palette.common.black, // Tooltip text color black
    fontSize: "0.8rem", // Increase text size
    fontWeight: "bold", // Make text bold
  },
}));

const LogoutButton = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLogout = () => {
    localStorage.clear(); // Clear the entire local storage
    navigate("/login");
    localStorage.removeItem("imageCount");
    localStorage.removeItem("imageDates");
    localStorage.removeItem("ndviThumbUrls");
    localStorage.removeItem("ndviGradientThumbUrls");
    localStorage.removeItem("ndmiGradientThumbUrls");
    localStorage.removeItem("ndmiThumbUrls");
    localStorage.removeItem("trueColorThumbUrls");
    localStorage.removeItem("ndviAreas");
    localStorage.removeItem("ndmiAreas");
  };

  // const handleLogout = () => {
  //   localStorage.removeItem("isAuthenticated");
  //   navigate("/login");
  // };

  return (
    <div style={{ color: "white", cursor: "pointer" }}>
      <BootstrapTooltip title={t("Logout")} onClick={handleLogout}>
        <LogoutIcon style={{ fontSize: 30 }} />
      </BootstrapTooltip>
    </div>
  );
};

export default LogoutButton;
