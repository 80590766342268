import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import "./CustomDrawer.css"; // Import the custom CSS
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import DrawIcon from "@mui/icons-material/Draw";
import HelpIcon from "@mui/icons-material/Help";
import { Tooltip, styled } from "@mui/material";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "black",
    color: "white",
    fontSize: 12,
  },
});

const CustomDrawer = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const theme = useTheme();
  const isLgScreen = useMediaQuery("(min-width:1100px)");
  const isSmOrMdScreen = useMediaQuery("(max-width:1099px)");

  const toggleDrawer = () => {
    if (isSmOrMdScreen) {
      setIsDrawerOpen(!isDrawerOpen);
    }
  };

  return (
    <div>
      <div
        className={`drawer-toggle-button ${isLgScreen ? "hidden" : ""}`}
        onClick={isSmOrMdScreen ? toggleDrawer : undefined}
      >
        <span className="drawer-toggle-text">
          {isDrawerOpen ? "Hide fields" : "Open fields"}
        </span>
        <span className="drawer-toggle-icon">
          {isDrawerOpen ? (
            <KeyboardDoubleArrowDownIcon />
          ) : (
            <KeyboardDoubleArrowUpIcon />
          )}
        </span>
      </div>
      <div
        className={`drawer ${isLgScreen ? "drawer-lg" : ""} ${
          isSmOrMdScreen ? (isDrawerOpen ? "drawer-open" : "drawer-closed") : ""
        }`}
      >
        <div>
          <div className="heading-parent">
            <div className="drawer-heading">Global View</div>
            <div className="info-icon-drawer">
            <CustomTooltip
              title="Info"
              placement="right"
            >
              <HelpIcon sx={{ fontSize: "22px" }} />
              </CustomTooltip>
            </div>
          </div>

          <div className="add-field-parent">
            <div className="add-field-child">
              <div className="draw-icon">
                <DrawIcon sx={{ fontSize: "30px" }} />
              </div>
              <div className="draw-text-parent">
                <div className="first-text-draw">Add your fields</div>
                <div className="second-text-draw">
                  Browse satellite images, vegetation indices, weather data and
                  much more
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomDrawer;
