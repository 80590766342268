import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import './NotFound.css'; // Optional for styling
import Vecter1 from "../Assets/Vecter/vecter-1.jpg"
import Vecter2 from "../Assets/Vecter/vectr-2.jpg"
import Vecter3 from "../Assets/Vecter/vecter-3.jpg"
import MapLoader from "../All-Loader/mapLoader"


const NotFound = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);


  const goBackHome = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      navigate("/"); // Navigate to the home page
    }, 2500); // Set a delay before navigation
  };

  if (loading) return <MapLoader />;


  return (
    <div className="not-found-container">
      <div className="vector-image">
        <img src={Vecter2} alt="Page Not Found" />
      </div>
      {/* <h1>404</h1> */}
      <h2>Oops! Page Not Found</h2>
      <p>We can't seem to find the page you're looking for.</p>
      <button onClick={goBackHome} className="back-home-btn">
        Go Back Home
      </button>
    </div>
  );
};

export default NotFound;
