import React, { useEffect, useState } from "react";
import axios from "axios";
// import "./CustomWeatherWidget.css";


const WeatherWidget = () => {
  const [forecastData, setForecastData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const city = "Okara";
  const apiKey = "4a1da579331826fb650783766abc4ec2";

  useEffect(() => {
    const fetchWeather = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          `https://api.openweathermap.org/data/2.5/forecast?q=${city}&appid=${apiKey}&units=metric`
        );
        setForecastData(response.data.list);
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    };

    fetchWeather();
  }, [city]);

  const getCurrentWeather = (data) => {
    if (data.length === 0) return null;
    return data[0];
  };

  const groupByDate = (data) => {
    const grouped = {};
    data.forEach((item) => {
      const date = item.dt_txt.split(" ")[0];
      if (!grouped[date]) {
        grouped[date] = [];
      }
      grouped[date].push(item);
    });
    return grouped;
  };

  const renderForecast = (groupedData) => {
    return Object.keys(groupedData).map((date, index) => {
      if (index > 7) return null; // Limiting to 7 days
      const dayData = groupedData[date][0];
      return (
        <div key={date} className="forecast-day">
          <span>
            {new Date(date).toLocaleDateString("en-US", {
              weekday: "short",
              day: "numeric",
              month: "short",
            })}
          </span>
          <img
            src={`http://openweathermap.org/img/wn/${dayData.weather[0].icon}@2x.png`}
            alt="weather icon"
          />
          <div className="temp-range">
            <span>{Math.round(dayData.main.temp_max)}°</span>
            <span>{Math.round(dayData.main.temp_min)}°</span>
          </div>
        </div>
      );
    });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error fetching data: {error.message}</p>;

  const currentWeather = getCurrentWeather(forecastData);
  const groupedData = groupByDate(forecastData);

  return (
    <div className="weather-widget-fsd" >
    
      <div className="forecast">{renderForecast(groupedData)}</div>
    </div>
  );
};

export default WeatherWidget;
