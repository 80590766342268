import React, { useEffect, useState } from "react";
import axios from "axios";
import "./CustomWeatherWidget.css";

import "./Live-Weather-Station/davisVantageWidget.css";
import { ThemeProvider, createTheme, useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import LogOutDropDown from "../LogOut/logOutDropDown";
import { useNavigate, useLocation } from "react-router-dom";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import MapLoader from "../All-Loader/mapLoader";
import KisanLogo from "../Assets/Logo/kisan-bg.png";
import HomeIcon from "@mui/icons-material/Home";

import {
  ArrowBackIos as ArrowBackIosIcon,
  Menu as MenuIcon,
} from "@mui/icons-material";

import {
  Drawer,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  Divider,
  ListItemText,
  IconButton,
  Typography,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import LanguageSwitcher from "../Language-Switcher/languageSwitcher";
import Feedback from "../Feedback/feedback";
import { Tooltip, styled } from "@mui/material";

// Import Zustand store
import useStore from "../../zustand/useStore";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, isRtl }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "black",
    color: "white",
    fontSize: isRtl ? 15 : 12, // Set fontSize based on RTL or LTR
  },
}));

const WeatherWidget = () => {
  const [forecastData, setForecastData] = useState([]);
  const [cityName, setCityName] = useState(""); // State for storing the city name
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { aoi, setAoi } = useStore(); // Destructure setAoi from the store
  const { t, i18n } = useTranslation();
  const isRtl = i18n.dir() === "rtl";
  const navigate = useNavigate();
  const location = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const apiKey = "4a1da579331826fb650783766abc4ec2";

  useEffect(() => {
    console.log("Retrieved AOI coordinates from Zustand:", aoi);

    if (!aoi || !Array.isArray(aoi) || aoi.length === 0) {
      console.error("Coordinates are missing or undefined:", aoi);
      return;
    }

    const { lat, lng } = aoi[0]; // Using the first set of coordinates

    if (!lat || !lng) {
      console.error("Latitude or Longitude is undefined:", lat, lng);
      return;
    }

    console.log("Using coordinates:", lat, lng);

    const fetchWeather = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          `https://api.openweathermap.org/data/2.5/forecast?lat=${lat}&lon=${lng}&appid=${apiKey}&units=metric`
        );
        console.log("Weather API response:", response.data); // Log the response
        setForecastData(response.data.list);
        setCityName(response.data.city.name); // Set the city name
      } catch (err) {
        console.error("Error fetching weather data:", err);
        setError(err);
      }
      setLoading(false);
    };

    fetchWeather();
  }, [aoi]);

  const getCurrentWeather = (data) => {
    if (data.length === 0) return null;
    return data[0];
  };

  const groupByDate = (data) => {
    const grouped = {};
    data.forEach((item) => {
      const date = item.dt_txt.split(" ")[0];
      if (!grouped[date]) {
        grouped[date] = [];
      }
      grouped[date].push(item);
    });
    return grouped;
  };

  const renderForecast = (groupedData) => {
    return Object.keys(groupedData).map((date, index) => {
      if (index > 7) return null; // Limiting to 7 days
      const dayData = groupedData[date][0];
      return (
        <div key={date} className="forecast-day">
          <span>
            {new Date(date).toLocaleDateString("en-US", {
              weekday: "short",
              day: "numeric",
              month: "short",
            })}
          </span>
          <img
            src={`http://openweathermap.org/img/wn/${dayData.weather[0].icon}@2x.png`}
            alt="weather icon"
          />
          <div className="temp-range">
            <span>{Math.round(dayData.main.temp_max)}°</span>
            <span>{Math.round(dayData.main.temp_min)}°</span>
          </div>
        </div>
      );
    });
  };

  const menuItems = [
    {
      text: "Weather Forecast",
      icon: <WbSunnyIcon />,
      link: "/WeatherForecast",
    },
    { text: "CAS Faisalabad", icon: <WbSunnyIcon />, link: "/CASFaisalabad" },
    { text: "Burewala", icon: <WbSunnyIcon />, link: "/BurewalaWeather" },
    { text: "Okara", icon: <WbSunnyIcon />, link: "/OkaraWeather" },
    { text: "Toba Campus", icon: <WbSunnyIcon />, link: "/TobaCampusWeather" },
    // {
    //   text: "Faisalabad 2",
    //   icon: <WbSunnyIcon />,
    //   link: "/DevisVantageWeather",
    // },
  ];
  const darkModeTheme = createTheme({
    palette: {
      mode: "dark",
      background: {
        default: "#212930",
      },
      text: {
        primary: "#9fb4c7",
      },
    },
  });

  const handleDrawerToggle = () => {
    setOpen(!open);
    setDropdownOpen(false);
  };

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const [open, setOpen] = useState(isLargeScreen);

  useEffect(() => {
    setOpen(isLargeScreen);
  }, [isLargeScreen]);

  if (loading) return <MapLoader />;
  if (error) return <p>Error fetching data: {error.message}</p>;

  const currentWeather = getCurrentWeather(forecastData);
  const groupedData = groupByDate(forecastData);

  const handleGoBack = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      navigate("/"); // Navigate to the home page
    }, 1500); // Set a delay before navigation
  };

  function getWindDirection(deg) {
    if (deg >= 337.5 || deg < 22.5) return "N";
    if (deg >= 22.5 && deg < 67.5) return "NE";
    if (deg >= 67.5 && deg < 112.5) return "E";
    if (deg >= 112.5 && deg < 157.5) return "SE";
    if (deg >= 157.5 && deg < 202.5) return "S";
    if (deg >= 202.5 && deg < 247.5) return "SW";
    if (deg >= 247.5 && deg < 292.5) return "W";
    if (deg >= 292.5 && deg < 337.5) return "NW";
  }

  return (
    <div>
      <ThemeProvider theme={darkModeTheme}>
        <div style={{ display: "flex", height: "100vh" }}>
          <Drawer
            variant="permanent"
            open={open}
            PaperProps={{
              sx: {
                backgroundColor: "#212930",
                color: "#9fb4c7",
                width: open ? 250 : 60, // Increased drawer width
                transition: "width 0.3s",
                overflowX: "hidden",
                position: "relative",
                display: "flex",
                flexDirection: "column",
              },
            }}
          >
            {/* Scrollable Content */}
            <Box
              sx={{
                flexGrow: 1,
                overflowY: "auto",
                overflowX: "hidden",
                maxHeight: "calc(100vh )",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px",
                  justifyContent: open ? "space-between" : "center",
                }}
              >
                <IconButton
                  onClick={handleDrawerToggle}
                  sx={{ color: "#9fb4c7" }}
                >
                  {open ? <ArrowBackIosIcon /> : <MenuIcon />}
                </IconButton>
                {open && <img src={KisanLogo} alt="logo" width={120} />}
              </Box>


              <Divider sx={{ backgroundColor: "#555" }} />
              {/* Home Icon */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  padding: "8px",
                  justifyContent: "center", // Center the icon horizontally
                  height: "60px", // Set a fixed height for the box
                  backgroundColor: "transparent",
                  "&:hover": {
                    // backgroundColor: "#2a3b4e",
                  },
                }}
                onClick={handleGoBack} // Navigate to home page
              >
                 <CustomTooltip title="Back to Home" placement="right">
                <IconButton>
                  <HomeIcon
                    sx={{
                      color: "#004ba1", // Orange color for the icon
                      fontSize: "2rem", // Increase the size of the icon
                      background:"white",
                      padding:"3px",
                      borderRadius:"10px 0px 10px 0px"
                    }}
                  />
                </IconButton>
                </CustomTooltip>
              </Box>

              {/* Custom Content */}
              <List>
                {menuItems.map((item, index) => (
                  <ListItem
                    key={index}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <CustomTooltip title={item.text} placement="right">
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                          borderRight:
                            location.pathname === item.link
                              ? "4px solid #004ba1"
                              : "none", // Add borderRight when active
                          backgroundColor:
                            location.pathname === item.link
                              ? "#2e3b4a"
                              : "transparent", // Optional: Highlight active item
                        }}
                        onClick={() => navigate(item.link)}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            minHeight: open ? "0px" : "50px",
                            color: "#9fb4c7",
                            alignItems: "center",
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={item.text}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </CustomTooltip>
                  </ListItem>
                ))}
              </List>
            </Box>

            {/* Fixed Bottom Section */}
            <Box sx={{ position: "relative" }}>
              <Divider sx={{ backgroundColor: "#555" }} />
              <Box
                sx={{
                  padding: open ? "10px" : "5px",
                  display: "flex",
                  flexDirection: open ? "row" : "column",
                  alignItems: "center",
                  Content: "center",
                  gap: open ? "0px" : "5px",
                  marginLeft: open ? "-20px" : "0px",
                  ...(isRtl ? { marginRight: "0px" } : { marginRight: "30px" }),

                  bottom: 0,
                  width: open ? 260 : 60, // Match drawer width
                  backgroundColor: "#212930", // Match drawer background
                }}
              >
                <div>
                  <LogOutDropDown />
                </div>
                <div
                  style={{
                    marginRight: open ? (isRtl ? "0" : "30px") : "0",
                    marginLeft: open ? (isRtl ? "20px" : "0") : "0",
                  }}
                >
                  <LanguageSwitcher />
                </div>
                <div>
                  <Feedback />
                </div>
              </Box>
            </Box>
          </Drawer>

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: "#212930",
              overflowY: "auto",
              maxHeight: "calc(100vh)",
              padding: 0,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <header className="weather-widget-header">
              <div
                style={{
                  padding: "10px",
                }}
              >
                <div className="d-flex justify-content-center mt-4">
                  <h3
                    style={{
                      margin: "0 10px",
                      color: "#9fb4c7",
                      fontWeight: "bold",
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "justify",
                    }}
                  >
                    {cityName} Weather Forecast
                  </h3>
                </div>
              </div>
            </header>

            {currentWeather ? (
              <div className="">
                {/* Display current weather if available */}
                <div style={{ display: "grid", justifyContent: "center" }}>
                  <div className="">
                    {/* <img
                      src={`http://openweathermap.org/img/wn/${currentWeather.weather[0].icon}@2x.png`}
                      alt="weather icon"
                    /> */}
                    <div
                      style={{
                        display: "grid",
                        justifyContent: "center",
                        color: "white",
                      }}
                    >
                      <h2 style={{ fontSize: "2rem" }}>
                        {Math.round(currentWeather.main.temp)}°C
                      </h2>
                      <p>{currentWeather.weather[0].description}</p>
                    </div>
                  </div>
                </div>

                <div className="custom-details" style={{ marginLeft: "15px" }}>
                  <div className="detail-item" style={{ color: "#9fb4c7" }}>
                    <i className="fas fa-tint"></i>
                    <span>Humidity: {currentWeather.main.humidity}%</span>
                  </div>
                  <div className="detail-item" style={{ color: "#9fb4c7" }}>
                    <i className="fas fa-cloud-rain"></i>
                    <span>
                      {" "}
                      Precipitation:{" "}
                      {currentWeather.rain
                        ? currentWeather.rain["1h"] + " mm"
                        : "0 mm"}
                    </span>
                  </div>
                  <div className="detail-item" style={{ color: "#9fb4c7" }}>
                    <i className="fas fa-tachometer-alt"></i>
                    <span>Pressure: {currentWeather.main.pressure} mb</span>
                  </div>

                  <div className="detail-item" style={{ color: "#9fb4c7" }}>
                    <i className="fas fa-wind"></i>
                    <span>Wind Speed: {currentWeather.wind.speed} km/h</span>
                  </div>

                  <div className="detail-item" style={{ color: "#9fb4c7" }}>
                    <i className="fas fa-compass"></i>
                    <span>
                      Wind Direction:{" "}
                      {getWindDirection(currentWeather.wind.deg)} (
                      {currentWeather.wind.deg}°)
                    </span>
                  </div>
                </div>
                {/* Display weather forecast */}
                <div className=" ">
                  <div className="forecast">{renderForecast(groupedData)}</div>
                </div>
              </div>
            ) : (
              // Display message if currentWeather is not available

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",

                  alignItems: "center",
                  height: "100%",
                  color: "#9fb4c7",
                  textAlign: "center",
                  padding: "20px",
                }}
              >
                <div
                  style={{
                    border: "1px dashed #3592fd",
                    backgroundColor: "rgba(53, 146, 253, 0.2)",
                    borderRadius: "8px",
                    padding: "16px",
                    display: "flex",
                    alignItems: "center",
                    textAlign: "justify",
                    cursor: "pointer",
                    gap: "10px",
                    transition: "background-color 0.3s, border-color 0.3s", // smooth transition for hover effects
                  }}
                  onClick={handleGoBack}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor =
                      "rgba(53, 146, 253, 0.3)"; // slightly darker on hover
                    e.currentTarget.style.borderColor = "#1e7bd7"; // change border color on hover
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor =
                      "rgba(53, 146, 253, 0.2)"; // revert to original background
                    e.currentTarget.style.borderColor = "#3592fd"; // revert to original border color
                  }}
                >
                  <HomeIcon />
                  Click to go back, draw your field, and
                  <br />
                  get the weather forecast for your field
                </div>
              </div>
            )}
          </Box>
        </div>
      </ThemeProvider>
    </div>
  );
};

export default WeatherWidget;
