import React, { useState, useEffect } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GeoHarvestLogo from "../Assets/Logo/geo-harvest-logo.png";
import { GoogleMap, useLoadScript, Polygon } from "@react-google-maps/api";
import CanvasOverlay from "./canvasOverlay";
import "./Copernicus-Harmonized.css";
import ee from "@google/earthengine";

const libraries = ["drawing"];

const EarthEngineImage = () => {
  const [imageCount, setImageCount] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [imageUrl, setImageUrl] = useState(null);
  const [error, setError] = useState(null);
  const [aoi, setAoi] = useState([]);
  const [bounds, setBounds] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 31.3656, lng: 72.9942 });
  const [groundOverlayOpacity, setGroundOverlayOpacity] = useState(0);
  const [polygonOpacity, setPolygonOpacity] = useState(0);
  const [availableImages, setAvailableImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageDate, setSelectedImageDate] = useState(null);
  const [apiKey, setApiKey] = useState(null);
  const [showNdvi, setShowNdvi] = useState(false);
  const [loadingApiKey, setLoadingApiKey] = useState(true); // New state for loading API key

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyC_4pCYuadBC7676P4zXOFZD9Oc1uDfo0M", // Replace with your Google Maps API key
    libraries,
  });

  useEffect(() => {
    const fetchApiKey = async () => {
      setLoadingApiKey(true); // Set loading state to true
      const baseUrl =
        process.env.NODE_ENV === "development"
          ? "http://localhost:8080"
          : "https://api.kisan360.net"; // dynamically set protocol (http or https)  
      try {
        const response = await axios.get(`${baseUrl}/token`);
        setApiKey(response.data.token);
      } catch (error) {
        console.error("Error fetching API key:", error);
        setError(
          error.response?.data?.message || "Error fetching API key. Please try again later."
        );
      } finally {
        setLoadingApiKey(false); // Set loading state to false
      }
    };
  
    fetchApiKey();
  }, []);
  

  // const maskS2clouds = (image) => {
  //   var qa = image.select("QA60");
  //   var cloudBitMask = 1 << 10;
  //   var cirrusBitMask = 1 << 11;
  //   var mask = qa
  //     .bitwiseAnd(cloudBitMask)
  //     .eq(0)
  //     .and(qa.bitwiseAnd(cirrusBitMask).eq(0));
  //   return image.updateMask(mask).divide(10000);
  // };

  const fetchImageData = async (start, end) => {
    if (!apiKey) {
      setError("API key not available");
      return;
    }

    let coords = [...aoi.map((coord) => [coord.lng, coord.lat])];
    if (
      coords.length > 0 &&
      JSON.stringify(coords[0]) !== JSON.stringify(coords[coords.length - 1])
    ) {
      coords.push(coords[0]);
    }

    console.log("AOI Coordinates:", coords);

    const project = "projects/earthengine-public";
    const assetId = "COPERNICUS/S2_SR_HARMONIZED";
    const name = `${project}/assets/${assetId}`;
    const startTime = start.toISOString();
    const endTime = new Date(end.getTime() + 24 * 60 * 60 * 1000).toISOString();
    const region = {
      type: "Polygon",
      coordinates: [coords],
    };
    const filter = "CLOUDY_PIXEL_PERCENTAGE < 10";

    try {
      const listImagesUrl = `https://earthengine.googleapis.com/v1alpha/${name}:listImages?${new URLSearchParams(
        {
          startTime,
          endTime,
          region: JSON.stringify(region),
          filter,
        }
      )}`;

      console.log("Fetching list of images:", listImagesUrl);
      const listImagesResponse = await axios.get(listImagesUrl, {
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
      });

      console.log("List images response:", listImagesResponse.data);

      const images = listImagesResponse.data.images;
      console.log("Number of available images:", images.length);
      setImageCount(images.length);
      setAvailableImages(images);

      if (!images || images.length === 0) {
        setError("No images found for the specified date range.");
        window.alert("Error: No images found for the specified date range.");
        setImageUrl(null);
        return;
      }

      const selectedAssetId = images[0].id;
      const selectedName = `${project}/assets/${selectedAssetId}`;
      setImageUrl(null); // Reset the image URL before fetching new data
      fetchImageDetails(
        selectedName,
        apiKey,
        region,
        new Date(images[0].startTime)
      );
    } catch (error) {
      console.error("Error fetching image data:", error);
      setError("Error fetching image data. Please try again later.");
      setImageUrl(null);
    }
  };

  const fetchImageDetails = async (imageName, apiKey, region, imageDate) => {
    try {
      const getImageDetailsUrl = `https://earthengine.googleapis.com/v1alpha/${imageName}`;
      console.log("Fetching image details:", getImageDetailsUrl);
      const getImageDetailsResponse = await axios.get(getImageDetailsUrl, {
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
      });

      console.log("Image details response:", getImageDetailsResponse.data);
      const asset = getImageDetailsResponse.data;
      const regionGeometry = asset.geometry;

      const getImagePixelsUrl = `https://earthengine.googleapis.com/v1alpha/${imageName}:getPixels`;
      const body = {
        fileFormat: "PNG",
        bandIds: showNdvi ? ["B8", "B4"] : ["B8", "B4", "B2"],
        region: region,
        grid: {
          dimensions: { width: 1000, height: 1000 },
        },
        visualizationOptions: showNdvi
          ? {
              ranges: [{ min: -1, max: 1 }],
              palette: ["blue", "white", "green"],
              expression: "(B8 - B4) / (B8 + B4)",
            }
          : {
              ranges: [{ min: 0, max: 3000 }],
            },
      };

      setGroundOverlayOpacity(1);
      setPolygonOpacity(0);

      console.log("Fetching image pixels:", getImagePixelsUrl, body);
      const getImagePixelsResponse = await axios.post(getImagePixelsUrl, body, {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json",
        },
        responseType: "arraybuffer",
      });

      console.log("Image pixels response:", getImagePixelsResponse);
      const imageBlob = new Blob([getImagePixelsResponse.data], {
        type: "image/png",
      });
      const imageUrl = URL.createObjectURL(imageBlob);
      setImageUrl(imageUrl);
      setSelectedImageDate(imageDate);
      setError(null);
    } catch (error) {
      console.error("Error fetching image details:", error);
      setError("Error fetching image details. Please try again later.");
      setImageUrl(null);
    }
  };

  const handleFetchImages = () => {
    if (!loadingApiKey) {
      fetchImageData(startDate, endDate);
    } else {
      setError("API key is still loading. Please try again shortly.");
    }
  };

  const handleLoad = (mapInstance) => {
    const drawingManager = new window.google.maps.drawing.DrawingManager({
      drawingMode: null,
      drawingControl: true,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.LEFT_CENTER,
        drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
      },
      polygonOptions: {
        fillColor: "#FF0000",
        fillOpacity: 0.3,
        strokeColor: "#FF0000",
        strokeOpacity: 0.6,
        strokeWeight: 1,
        clickable: false,
        editable: true,
        draggable: false,
        geodesic: false,
      },
    });

    drawingManager.setMap(mapInstance);

    window.google.maps.event.addListener(
      drawingManager,
      "overlaycomplete",
      (event) => {
        if (event.type === window.google.maps.drawing.OverlayType.POLYGON) {
          const newPolygon = event.overlay
            .getPath()
            .getArray()
            .map((latLng) => ({
              lat: latLng.lat(),
              lng: latLng.lng(),
            }));

          setAoi(newPolygon);

          const bounds = new window.google.maps.LatLngBounds();
          newPolygon.forEach((point) => {
            bounds.extend(new window.google.maps.LatLng(point.lat, point.lng));
          });
          console.log("AOI Bounds:", bounds.toJSON());
          setBounds(bounds);
        }
      }
    );
  };

  const handleGroundOverlayOpacityChange = (event) => {
    setGroundOverlayOpacity(parseFloat(event.target.value));
  };

  const handlePolygonOpacityChange = (event) => {
    setPolygonOpacity(parseFloat(event.target.value));
  };

  const handleImageSelection = (event) => {
    const selectedImageId = event.target.value;
    const selectedImage = availableImages.find(
      (image) => image.id === selectedImageId
    );
    if (selectedImage) {
      setSelectedImage(selectedImage);
      const coords = [...aoi.map((coord) => [coord.lng, coord.lat])];
      if (
        coords.length > 0 &&
        JSON.stringify(coords[0]) !== JSON.stringify(coords[coords.length - 1])
      ) {
        coords.push(coords[0]);
      }

      fetchImageDetails(
        `projects/earthengine-public/assets/${selectedImage.id}`,
        apiKey,
        {
          type: "Polygon",
          coordinates: [coords],
        },
        new Date(selectedImage.startTime)
      );
    }
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div>
      <div className="navbar-parent">
        <nav
          className="navbar navbar-light"
          style={{ background: "linear-gradient(180deg, #42424a, #191919)" }}
        >
          <a className="navbar-brand" href="/">
            <img
              src={GeoHarvestLogo}
              width="40"
              height="40"
              className="align-center"
              alt=""
            />
            <span style={{ color: "white", margin: "5px" }}>Geo Harvest</span>
          </a>
        </nav>
      </div>
      <div className="row" style={{ padding: "10px" }}>
        <div className="col-lg-2">
          <div className="d-flex flex-column mb-4 gap-3">
            <label style={{ fontWeight: "bolder" }}>Start Date: </label>
            <div>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </div>
            <label style={{ fontWeight: "bolder" }}>End Date: </label>
            <div>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
              />
            </div>
            <button
              className="btn btn-danger btn-sm"
              onClick={handleFetchImages}
              style={{ width: "50%" }}
              disabled={loadingApiKey} // Disable button while loading API key
            >
              Fetched
            </button>
            <p>
              <span style={{ fontWeight: "bolder" }}>Available images:</span>
              {""}
              <span
                style={{ color: "red", fontWeight: "bolder", margin: "3px" }}
              >
                {imageCount}
              </span>
            </p>
          </div>
          <div className="d-flex flex-column mb-4">
            <label className="mb-3" style={{ fontWeight: "bolder" }}>
              Select Image Date:{" "}
            </label>
            <select onChange={handleImageSelection} style={{ width: "80%" }}>
              <option value="">mm-dd-yyyy</option>
              {availableImages.map((image) => (
                <option key={image.id} value={image.id}>
                  {new Date(image.startTime).toLocaleDateString()}
                </option>
              ))}
            </select>
          </div>
          {selectedImageDate && (
            <div className="d-flex justify-content-around mb-3">
              <h6>
                Selected Image Date: {selectedImageDate.toLocaleDateString()}
              </h6>
            </div>
          )}
          <div className="d-flex flex-column gap-3">
            <label style={{ fontWeight: "bolder" }}>Image Opacity: </label>
            <div style={{ margin: "5px 0" }}>
              <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={groundOverlayOpacity}
                onChange={handleGroundOverlayOpacityChange}
              />
              <label style={{ marginLeft: "10px" }}>
                {groundOverlayOpacity}
              </label>
            </div>
            <label style={{ fontWeight: "bolder" }}>Polygon Opacity: </label>
            <div style={{ margin: "5px 0" }}>
              <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={polygonOpacity}
                onChange={handlePolygonOpacityChange}
              />
              <label style={{ marginLeft: "10px" }}>{polygonOpacity}</label>
            </div>
          </div>
        </div>
        <div className="col-lg-10">
          <div style={{ height: "100%", width: "100%", position: "relative" }}>
            <GoogleMap
              zoom={14}
              center={mapCenter}
              mapContainerClassName="map-container"
              onLoad={handleLoad}
              mapTypeId="satellite"
            >
              {aoi.length > 0 && (
                <Polygon
                  paths={aoi}
                  options={{
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.3,
                    strokeWeight: 0.3,
                    fillColor: "#FF0000",
                    fillOpacity: polygonOpacity,
                  }}
                />
              )}
            </GoogleMap>
            {bounds && imageUrl && (
              <CanvasOverlay
                bounds={bounds.toJSON()}
                imageUrl={imageUrl}
                opacity={groundOverlayOpacity}
                aoi={aoi}
              />
            )}
          </div>
          {error && <div style={{ color: "red" }}>Error: {error}</div>}
        </div>
      </div>
    </div>
  );
};

export default EarthEngineImage;
