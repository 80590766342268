import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box, Typography } from "@mui/material";
import CircularLoader from "../All-Loader/mapLoader"; // Import CircularLoader
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Menu, IconButton } from "@mui/material";
import TranslateIcon from "@mui/icons-material/Translate";
import "./languageSwitcher.css"; // Import the CSS file for fonts
import { ListItemText, Tooltip, Collapse, Switch, styled } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "black",
    color: "white",
    fontSize: 12,
  },
});

const LanguageSwitcher = () => {
  const [age, setAge] = useState("");
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = createTheme({
    typography: {
      fontFamily:
        i18n.language === "ur"
          ? "Noto Nastaliq Urdu, serif"
          : "Arial, sans-serif",
    },
    components: {
      MuiFormControl: {
        styleOverrides: {
          root: {
            color: "white",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "white",
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            color: "white",
          },
          root: {
            color: "white",
          },
          select: {
            "&:focus": {
              backgroundColor: "transparent",
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: "rgba(255, 255, 255, 0.2)",
            },
            "&.Mui-selected:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.3)",
            },
          },
        },
      },
    },
  });

  useEffect(() => {
    const storedLang = localStorage.getItem("language") || "en";
    i18n.changeLanguage(storedLang);
    setAge(storedLang === "en" ? 10 : 20);
    document.documentElement.setAttribute(
      "dir",
      storedLang === "ur" ? "rtl" : "ltr"
    );
  }, [i18n]);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const changeLanguage = (lng) => {
    setLoading(true);
    setTimeout(() => {
      i18n.changeLanguage(lng);
      setLoading(false);
      localStorage.setItem("language", lng);
      setAge(lng === "en" ? 10 : 20);
      document.documentElement.setAttribute(
        "dir",
        lng === "ur" ? "rtl" : "ltr"
      );
    }, 2500); // Simulate a delay for loading
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        {loading && (
          <Box display="flex" justifyContent="center" alignItems="center" p={2}>
            <CircularLoader />
          </Box>
        )}
        {!loading && (
          <Box sx={{ m: 1 }}>
            <IconButton onClick={handleIconClick}>
              <CustomTooltip
                title={t("Language Switcher")}
                placement={i18n.dir() === "rtl" ? "left" : "right"}
              >
                <TranslateIcon sx={{ color: "white" }} />
              </CustomTooltip>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top", // Position the menu above the icon
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "bottom", // Expand the menu upwards
                horizontal: "left",
              }}
              sx={{
                "& .MuiPaper-root": { color: "white", background: "black" },
              }}
            >
              <MenuItem
                onClick={() => {
                  changeLanguage("en");
                  handleClose();
                }}
              >
                <Box display="flex" alignItems="center" className="gap-3">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
                    alt="USA Flag"
                    width="20"
                    height="15"
                  />
                  <Typography>Eng</Typography>
                </Box>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  changeLanguage("ur");
                  handleClose();
                }}
              >
                <Box display="flex" alignItems="center" className="gap-3">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/3/32/Flag_of_Pakistan.svg"
                    alt="Pakistan Flag"
                    width="20"
                    height="15"
                  />
                  <Typography>اردو</Typography>
                </Box>
              </MenuItem>
            </Menu>
          </Box>
        )}
      </div>
    </ThemeProvider>
  );
};

export default LanguageSwitcher;
