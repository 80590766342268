import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  pdf,
  Font,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import urduFont from "../fonts/Amiri-Regular.ttf";
// import PdfFront from "../Assets/Pdf-Images/pdf-front-page1.png";

// Register Urdu font
Font.register({
  family: "Amiri",
  src: urduFont,
});

// Check if fonts are correctly registered
console.log("Fonts registered:", Font.getRegisteredFonts());

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFFFFF",
    padding: 20,
    fontFamily: "Amiri",
  },
  imageUrdu: {
    width: "100%",
    height: "auto",
  },
  imageUrduPg1: {
    width: "100%",
    height: "100%",
  },
  section: {
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: "60%",
    height: "auto",
    textAlign: "center",
    marginLeft: "25%",
  },
  // imageScreenShotNdvi: {
  //   width: "60%",
  //   height: "auto",
  //   textAlign: "center",
  //   marginLeft: "25%",
  // },
  title: {
    fontSize: 36,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 10,
    textAlign: "left",
  },
  subtitleUrdu: {
    fontSize: 10,
    margin: 10,
    direction: "rtl", // Ensure right-to-left text direction
    letterSpacing: "1px",
  },
  text: {
    fontSize: 14,
    textAlign: "left",
    margin: 10,
  },
  field: {
    fontSize: 14,
    textAlign: "left",
    margin: 5,
    borderBottom: "1px solid black",
  },
  legendContainer: {
    padding: 5,
    backgroundColor: "white",
    borderRadius: 5,
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
    margin: 20,
  },
  gradientBarContainer: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "flex-end", // Aligns children to the right
    height: 15,
    width: "70%",
    marginLeft: "30%",
    border: "1px solid black",
  },
  gradientStep: {
    flex: 1,
    height: "100%",
  },
  legendTextContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  legendText: {
    fontWeight: "bold",
    fontSize: 14,
  },
  sectionTitle: {
    fontSize: 22,
    textAlign: "right",
    marginBottom: 15,
  },
  projectTitle: {
    fontSize: 18,
    textAlign: "center",
    marginBottom: 30,
    marginTop: -20, // Adjusting top margin to fit layout
  },
  titleP2: {
    fontSize: 30,
    textAlign: "center",
    marginBottom: 40,
  },
  sectionTitleP2: {
    fontSize: 18,
    textAlign: "right",
    marginBottom: 10,
    borderBottom: "1px solid black",
  },
  sectionTitleP3: {
    fontSize: 18,
    textAlign: "right",
    marginBottom: 10,
  },
  titleP2R2: {
    fontSize: 22,
    textAlign: "right",
    marginBottom: 50,
    marginTop: 20,
    fontWeight: 600,
  },
  titleP3R2: {
    fontSize: 22,
    textAlign: "right",
  },
  projectText: {
    fontSize: 18,
    textAlign: "right",
    lineHeight: 1.5,
  },
});

const PdfDocument = ({
  ndviUrl,
  ndmiUrl,
  urduText,
  mapImage,
  engText,
  engText1,
  engText2,
  farmerName,
  reportDate,
  polygonImageUrl,
  min = "Low",
  max = "Dense",
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      {/* <Image style={styles.imageUrduPg1} src={PdfFront} /> */}
    </Page>
    <Page size="A4" style={styles.page}>
      <Text style={styles.title}>تعارف</Text>
      <Text style={styles.sectionTitle}> : پروجیکٹ کا عنوان</Text>
      <Text style={styles.projectTitle}>
        "کمپیوٹر وژن کا استعمال کرتے ہوئے جدید زراعت"
      </Text>
      <Text style={styles.sectionTitle}>: پروجیکٹ کا تعارف</Text>
      <Text style={styles.projectText}>
        یہ پروجیکٹ "اے ڈی پی سی" کے تعاون سے جدید مصنوعی ذہانت اور سیٹلائٹ
        تصاویر کا استعمال کرتے ہوئے، زراعت کے لئے فصلوں کی نگرانی کو بہتر بنانے
        کی کوشش کرتا ہے۔ اس منصوبے کا مقصد فصلوں کی صحت، پانی کی سطح، اور مٹی کی
        حالت کی نگرانی کرکے کسانوں کو درست معلومات فراہم کرنا ہے تاکہ وه بہتر
        فيصلے کر سکيں۔
      </Text>
    </Page>
    <Page size="A4" style={styles.page}>
      <Text style={styles.titleP2}>کسان کی معلومات </Text>
      <Text style={styles.sectionTitleP2}>{farmerName} : کسان کا نام </Text>
      <Text style={styles.sectionTitleP2}>25rb : علاقہ</Text>
      <Text style={styles.sectionTitleP2}>+92-300-0000-000 : رابطہ نمبر</Text>
      <Text style={styles.sectionTitleP2}> فصل کی قسم : گندم </Text>
      <Text style={styles.sectionTitleP2}>
        {reportDate} : فصل کی بوائی کی تار يخ
      </Text>
      {/* Display report date */}
      <Text style={styles.titleP2R2}>: کهيت کی تصوير</Text>
      {/* {polygonImageUrl && <Image style={styles.image} src={polygonImageUrl} />} */}
      <View style={styles.imageScreenShotNdvi}>
        {mapImage && (
          <Image style={styles.imageScreenShotNdvi} src={mapImage} />
        )}
        {!mapImage && (
          <Text style={styles.subtitle}>نقشہ کی تصویر دستیاب نہیں ہے۔</Text>
        )}
      </View>{" "}
    </Page>
    <Page size="A4" style={styles.page}>
      <Text style={styles.titleP2}>فصل کی صحت کی تجزيہ</Text>
      <Text style={styles.projectText}>
        این ڑی وی ای، فصلوں کی صحت کی نشاندہی کرتا ہے۔ يہ انڈيکس مختلف رنگوں ميں
        ظاہر کيا جاتا ہے، جس سے پتہ چلتا ہے کہ کونسی جگہ پر فصل کی حالت کيسی ہے۔
      </Text>
      <Text style={styles.titleP2R2}>: (NDVI) کهيت کی تصوير</Text>
      {/* {ndviUrl && <Image style={styles.image} src={ndviUrl} />} */}
      <View style={styles.imageScreenShotNdvi}>
        {mapImage && (
          <Image style={styles.imageScreenShotNdvi} src={mapImage} />
        )}
        {!mapImage && (
          <Text style={styles.subtitle}>نقشہ کی تصویر دستیاب نہیں ہے۔</Text>
        )}
      </View>
      <Text style={styles.titleP3R2}> : لیجنڈ (NDVI) </Text>
      <Text style={styles.sectionTitleP3}> گہرا سبز : بہترين صحت </Text>
      <Text style={styles.sectionTitleP3}> ھلکا سبز : بہتر صحت </Text>
      <Text style={styles.sectionTitleP3}> پيلا : اوسط صحت </Text>
      <Text style={styles.sectionTitleP3}> سرخ : کوئی سبزه نہيں </Text>
    </Page>
    <Page size="A4" style={styles.page}>
      <Text style={styles.titleP2}>فصل کی صحت کی تجزيہ</Text>
      <Text style={styles.projectText}>
        این ڑی ایم ای، فصلوں کی صحت کی نشاندہی کرتا ہے۔ يہ انڈيکس مختلف رنگوں
        ميں ظاہر کيا جاتا ہے، جس سے پتہ چلتا ہے کہ کونسی جگہ پر فصل کی حالت کيسی
        ہے۔
      </Text>
      <Text style={styles.titleP2R2}>: (NDMI) کهيت کی تصوير</Text>
      {ndmiUrl && <Image style={styles.image} src={ndmiUrl} />}
      <Text style={styles.titleP3R2}> : لیجنڈ (NDMI) </Text>
      <Text style={styles.sectionTitleP3}> سفید : خشک </Text>
      <Text style={styles.sectionTitleP3}> نیلا : نمی </Text>
    </Page>
  </Document>
);

export const generatePdfDocument = async (
  ndviUrl,
  ndmiUrl,
  mapImage,
  engText,
  urduText,
  engText1,
  engText2,
  farmerName,
  reportDate,
  polygonImageUrl
) => {
  const blob = await pdf(
    <PdfDocument
      ndviUrl={ndviUrl}
      ndmiUrl={ndmiUrl}
      mapImage={mapImage}
      engText={engText}
      urduText={urduText}
      engText1={engText1}
      engText2={engText2}
      farmerName={farmerName}
      reportDate={reportDate}
      polygonImageUrl={polygonImageUrl}
    />
  ).toBlob();
  saveAs(blob, "document.pdf");
};
