import React from "react";
import {
  Document,
  Page,
  Text,
  StyleSheet,
  Font,
  pdf,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import urduFont from "../fonts/Amiri-Regular.ttf";

// Register Urdu font
Font.register({
  family: "Amiri",
  src: urduFont,
});

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontFamily: "Amiri",
  },
  title: {
    fontSize: 36,
    textAlign: "center",
    marginBottom: 40,
  },
  titleP2: {
    fontSize: 30,
    textAlign: "center",
    marginBottom: 40,
  },
  sectionTitle: {
    fontSize: 22,
    // fontWeight:"extrabold",
    textAlign: "right",
    marginBottom: 15,
  },
  sectionTitleP2: {
    fontSize: 18,
    // fontWeight:"extrabold",
    textAlign: "right",
    marginBottom: 10,
  },
  titleP2R2: {
    fontSize: 24,
    textAlign: "center",
    marginBottom: 40,
  },
  text: {
    fontSize: 14,
    textAlign: "right",
    lineHeight: 1.5,
  },
  projectTitle: {
    fontSize: 18,
    textAlign: "center",
    marginBottom: 30,
    marginTop: -20, // Adjusting top margin to fit layout
  },
  projectText: {
    fontSize: 18,
    textAlign: "right",
    lineHeight: 1.5,
    // marginBottom: 10,
  },
});

const MyDocument = () => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.title}>تعارف</Text>
      <Text style={styles.sectionTitle}>پروجیکٹ کا عنوان</Text>
      <Text style={styles.projectTitle}>
        "کمپیوٹر وژن کا استعمال کرتے ہوئے جدید زراعت"
      </Text>
      <Text style={styles.sectionTitle}>پروجیکٹ کا تعارف</Text>
      <Text style={styles.projectText}>
        یہ پروجیکٹ "اے ڈی پی سی" کے تعاون سے جدید مصنوعی ذہانت اور سیٹلائٹ
        تصاویر کا استعمال کرتے ہوئے، زراعت کے لئے فصلوں کی نگرانی کو بہتر بنانے
        کی کوشش کرتا ہے۔ اس منصوبے کا مقصد فصلوں کی صحت، پانی کی سطح، اور مٹی کی
        حالت کی نگرانی کرکے کسانوں کو درست معلومات فراہم کرنا ہے تاکہ وه بہتر
        فيصلے کر سکيں۔
      </Text>
    </Page>
    <Page size="A4" style={styles.page}>
      <Text style={styles.titleP2}>کسان کی معلومات </Text>
      <Text style={styles.sectionTitleP2}>کسان کا نام </Text>
      <Text style={styles.sectionTitleP2}>علاقہ </Text>
      <Text style={styles.sectionTitleP2}>رابطہ نمبر</Text>
      <Text style={styles.sectionTitleP2}>فصل کی قسم</Text>
      <Text style={styles.sectionTitleP2}>فصل کی بوائی کی تار يخ</Text>

      <Text style={styles.titleP2R2}>کهيت کی تصوير</Text>
    </Page>
  </Document>
);

const App = () => {
  const handleDownload = async () => {
    const blob = await pdf(<MyDocument />).toBlob();
    saveAs(blob, "document.pdf");
  };

  return (
    <div>
      <button onClick={handleDownload}>Download PDF</button>
    </div>
  );
};

export default App;
