import React, { useState } from "react";
import { Tooltip, IconButton, Menu, MenuItem, styled } from "@mui/material";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MapLoader from "../All-Loader/mapLoader"; // Import your MapLoader component

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, isRtl }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "black",
    color: "white",
    fontSize: isRtl ? 15 : 12,
  },
}));

const Feedback = () => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false); // State to control loader visibility
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFeedbackClick = () => {
    const phoneNumber = "+923001750077";
    const message = encodeURIComponent(
      "Welcome in Kisan 360, please share your feedback with us."
    );
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappUrl, "_blank");
    handleMenuClose();
  };

  const handleContactUs = () => {
    navigate("/contact-us");
    handleMenuClose();
  };

  const handleAboutUs = () => {
    setLoading(true); // Show loader
    setTimeout(() => {
      setLoading(false); // Hide loader before navigating
      navigate("/AboutUs");
    }, 2500); // Adjust the delay as needed

    handleMenuClose();
  };

  const isMenuOpen = Boolean(anchorEl);
  const menuId = "feedback-dropdown-menu";

  return (
    <>
      {loading && <MapLoader />} {/* Show loader if loading is true */}
      <CustomTooltip
        title={t("Send Feedback")}
        placement={i18n.dir() === "rtl" ? "left" : "right"}
      >
        <IconButton onClick={handleMenuOpen} sx={{ color: "white" }}>
          <FeedbackIcon fontSize="medium" />
        </IconButton>
      </CustomTooltip>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        id={menuId}
        keepMounted
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
        sx={{ "& .MuiPaper-root": { color: "white", backgroundColor: "black" } }}
      >
        <MenuItem onClick={handleFeedbackClick}>
          {t("Feedback")}
        </MenuItem>
        <MenuItem onClick={handleAboutUs}>
          {t("About Us")}
        </MenuItem>
      </Menu>
    </>
  );
};

export default Feedback;
