import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ShareIcon from "@mui/icons-material/Share";

import {
  IconButton,
  useMediaQuery,
  Modal,
  Box,
  Tooltip,
  Button,
  styled,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, isRtl }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "black",
    color: "white",
    fontSize: isRtl ? 15 : 12,
  },
}));

const Legend = ({ ndviAreas }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const [isOpen, setIsOpen] = useState(isLgUp);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [audioSrc, setAudioSrc] = useState(null);
  const isRtl = i18n.dir() === "rtl";
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setIsOpen(isLgUp);
  }, [isLgUp]);

  const toggleLegend = () => {
    setIsOpen((prevOpen) => !prevOpen);
  };

  const openModal = async () => {
    setLoading(true); // Start loading

    if (audioSrc) {
      // Stop the current audio if playing
      const audioElement = document.getElementById("audioPlayer");
      if (audioElement) {
        audioElement.pause(); // Pause the current audio
        audioElement.currentTime = 0; // Reset playback time
      }
      // Revoke the previous blob URL to free up memory
      window.URL.revokeObjectURL(audioSrc);
      setAudioSrc(null);
    }

    setTimeout(async () => {
      const totalAcreage = Math.round(
        (ndviAreas?.Category4_Area_acres || 0) +
          (ndviAreas?.Category3_Area_acres || 0) +
          (ndviAreas?.Category2_Area_acres || 0) +
          (ndviAreas?.Category1_Area_acres || 0)
      );

      const ndvi4Text = ndviAreas?.ndvi4Area
        ? `${ndviAreas.ndvi4Area} ايکڑ رقبہ بہترين حالت ميں ہے اور اسے گہرے سبز ميں دکھایا گيا ہے۔`
        : "";

      const ndvi3Text = ndviAreas?.ndvi3Area
        ? `${ndviAreas.ndvi3Area} ايکڑ رقبہ اچھی حالت ميں ہے اور اسے ہلکے سبز ميں دکھایا گيا ہے۔`
        : "";

      const ndvi2Text = ndviAreas?.ndvi2Area
        ? `${ndviAreas.ndvi2Area} ايکڑ رقبہ متوسط حالت ميں ہے اور اسے نارن جی ميں دکھایا گيا ہے۔`
        : "";

      const ndvi1Text = ndviAreas?.ndvi1Area
        ? `${ndviAreas.ndvi1Area} ايکڑ رقبہ خراب حالت ميں ہے اور اسے سرخ ميں دکھایا گيا ہے۔`
        : "";

      const text = `
      معزز کسان، آپکو کسان360 کی ٹیم کی جانب سے خوش آمدید- آپ کی فصل کی مجودہ سیٹلائٹ کی تصویر پیش خدمت ہے۔
      
      آپ کی فصل کا قل رقبہ تقریبن ${totalAcreage} ایکڑ ہے۔
      
   
فصل کی مجموعی صحت, نمی اور نائٹروجن کی مقدار کے تجزیے دیکھنے کے لیے نقشے کو تبدیل کریں شکریہ۔    `;

      try {
        const baseUrl =
          process.env.NODE_ENV === "development"
            ? "http://localhost:8080"
            : "https://api.kisan360.net"; // dynamically set the base URL

        const response = await fetch(`${baseUrl}/api/synthesize`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ text }),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch audio.");
        }

        const blob = await response.blob(); // Get the binary data (Blob)
        const audioUrl = window.URL.createObjectURL(blob); // Create a URL for the blob

        setAudioSrc(audioUrl); // Set the blob URL for the audio player
        setIsModalOpen(true);

        // Automatically play the new audio
        const newAudioElement = document.getElementById("audioPlayer");
        if (newAudioElement) {
          newAudioElement.src = audioUrl;
          newAudioElement.play(); // Play the new audio
        }
      } catch (err) {
        console.error("Error fetching the audio:", err);
      } finally {
        setLoading(false); // End loading after audio is ready
      }
    }, 2500); // 2.5-second delay
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setAudioSrc(null);
  };

  const downloadAudio = () => {
    const link = document.createElement("a");
    link.href = audioSrc;
    link.download = "audio-report.mp3";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const legendContainerStyle = {
    width: isOpen ? (isMdDown ? "230px" : "300px") : isMdDown ? "30px" : "35px",
    backgroundColor: "#212930",
    padding: isOpen ? "8px" : "16px 5px",
    boxSizing: "border-box",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
    transition: "width 0.5s ease",
    color: "white",
    marginTop: "180px",
    zIndex: "-1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    direction: isRtl ? "rtl" : "ltr",
    height: isOpen ? "" : "0px",
  };

  const toggleArrowStyle = {
    position: "absolute",
    top: "50%",
    [isRtl ? "left" : "right"]: "-20px",
    color: "white",
    transform: "translateY(-50%)",
    backgroundColor: "#004ba1",
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)",
  };

  const itemContainerStyle = {
    display: "flex",
    alignItems: "center",
    margin: "5px 0",
  };

  const colorBoxStyle = (color) => ({
    backgroundColor: color,
    width: "20px",
    height: "20px",
    marginRight: isRtl ? "0" : "10px",
    marginLeft: isRtl ? "10px" : "0",
  });

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white", // Light background color
    padding: "25px",
    borderRadius: "10px",
    boxShadow: "0 0 15px rgba(0, 0, 0, 0.3)", // Light shadow for subtle effect
    outline: "none",
    maxWidth: "500px",
    width: "90%",
    textAlign: "center",
  };

  const closeButtonStyle = {
    position: "absolute",
    top: "0px",
    right: "0px",
    color: "black",
  };

  const downloadButtonStyle = {
    marginTop: "15px",
    backgroundColor: "#1976d2",
    color: "white",
    padding: "8px 16px",
    borderRadius: "5px",
    boxShadow: "0 3px 5px rgba(0, 0, 0, 0.2)",
  };

  const shareAudioOnWhatsApp = () => {
    if (audioSrc) {
      // Prepare the share message with the audio URL
      const textMessage = encodeURIComponent(
        `Here's the audio report of your crop health. You can listen to it by clicking the following link: ${audioSrc}`
      );
      const whatsappUrl = `https://api.whatsapp.com/send?text=${textMessage}`;

      // Open WhatsApp share
      window.open(whatsappUrl, "_blank");
    }
  };

  const totalAcreage = Math.round(
    (ndviAreas?.Category4_Area_acres || 0) +
      (ndviAreas?.Category3_Area_acres || 0) +
      (ndviAreas?.Category2_Area_acres || 0) +
      (ndviAreas?.Category1_Area_acres || 0)
  );

  const items = [
    {
      color: "#008000",
      label: `${t("Excellent Condition")}: ${
        ndviAreas?.ndvi4Area || "N/A"
      }  ${t("Acres")}`,
    },
    {
      color: "#00FF00",
      label: `${t("Good Condition")}: ${ndviAreas?.ndvi3Area || "N/A"}  ${t(
        "Acres"
      )}`,
    },
    {
      color: "#FFA500",
      label: `${t("Average Condition")}: ${ndviAreas?.ndvi2Area || "N/A"}  ${t(
        "Acres"
      )}`,
    },
    {
      color: "#FF0000",
      label: `${t("Poor Condition")}: ${ndviAreas?.ndvi1Area || "N/A"}  ${t(
        "Acres"
      )}`,
    },
  ];

  return (
    <div className="legend-container" style={legendContainerStyle}>
      <CustomTooltip
        title={t("Legend NDVI")}
        placement={i18n.dir() === "rtl" ? "left" : "right"}
      >
        <IconButton
          onClick={toggleLegend}
          className="toggle-arrow"
          style={toggleArrowStyle}
        >
          {isOpen ? (
            isRtl ? (
              <ArrowForwardIosIcon />
            ) : (
              <ArrowBackIosIcon />
            )
          ) : isRtl ? (
            <ArrowBackIosIcon />
          ) : (
            <ArrowForwardIosIcon />
          )}
        </IconButton>
      </CustomTooltip>

      {isOpen && (
        <div className="legend-content" style={{ overflow: "hidden" }}>
          <div
            style={{
              fontWeight: "bold",
              fontSize: "18px",
              margin: "10px 0",
              borderBottom: "1px solid black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "100px",
            }}
          >
            {t("Satellite Image")}
            <IconButton onClick={openModal}>
              <CustomTooltip
                title={t("Audio Report")}
                placement={i18n.dir() === "rtl" ? "left" : "right"}
              >
                {loading ? (
                  <CircularProgress
                    style={{ color: "white", width: "24px", height: "24px" }}
                  />
                ) : (
                  <VolumeUpIcon style={{ color: "white" }} />
                )}
              </CustomTooltip>
            </IconButton>
          </div>
          {/* {items.map((item, index) => (
            <Tooltip key={index} title={item.tooltip || ""}>
              <div style={itemContainerStyle} className="gap-2">
                <div style={colorBoxStyle(item.color)}></div>
                <span>{item.label}</span>
              </div>
            </Tooltip>
          ))} */}
          <div
            style={{
              fontSize: "17px",
              margin: "10px 0",
              display: "flex",
              justifyContent: "center",
              //   borderTop: "1px solid black",
              padding: "50px",
            }}
          >
            {t("Total Area")} : {totalAcreage} {t("Acres")}
          </div>
          {/* {audioSrc && (
            <div>
              <audio
                controls
                autoPlay
                style={{
                  width: "100%",
                  padding: "10px",
                  borderTop: "1px solid black",
                  paddingTop: "15px",
                }}
              >
                <source src={audioSrc} type="audio/mp3" />
                Your browser does not support the audio element.
              </audio>
            </div>
          )} */}
        </div>
      )}

      {audioSrc && (
        <div>
          <audio
            controls
            autoPlay
            style={{
              width: isOpen
                ? isMdDown
                  ? "230px"
                  : "300px"
                : isMdDown
                ? "0px"
                : "0px",
              padding: isOpen ? "10px" : "0",
              borderTop: isOpen ? "1px solid black" : "none",
              paddingTop: isOpen ? "15px" : "0",
              visibility: isOpen ? "visible" : "visible", // Always visible
            }}
          >
            <source src={audioSrc} type="audio/mp3" />
            Your browser does not support the audio element.
          </audio>
        </div>
      )}

      {/* <Modal
        open={isModalOpen}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            closeModal();
          }
        }}
        BackdropProps={{ invisible: true }} 
      >
        <Box style={modalStyle}>
          <IconButton style={closeButtonStyle} onClick={closeModal}>
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h5"
            style={{
              color: "black",
              marginBottom: "10px",
              display: "flex",
              justifyContent: "center",
              fontWeight: "600",
            }}
          >
            {t("Audio Report")}
          </Typography>
          {audioSrc && (
            <>
              <audio controls autoPlay style={{ width: "100%" }}>
                <source src={audioSrc} type="audio/mp3" />
                Your browser does not support the audio element.
              </audio>
            
              <Button
                startIcon={<ShareIcon />}
                style={{
                  ...downloadButtonStyle,
                  marginTop: "10px",
                  backgroundColor: "#25D366",
                }}
                onClick={shareAudioOnWhatsApp}
              >
                {t("Share on WhatsApp")}
              </Button>
            </>
          )}
        </Box>
      </Modal> */}
    </div>
  );
};

export default Legend;
