// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: {
      "Select Farmer": "Select Farmer",
      "Fetch Images": "Fetch Images",
      "Reset All": "Reset All",
      "Available images": "Available images",
      "Select Report Date": "Select Report Date",
      "Select Map Type": "Select Map Type",
      "Crop Health": "Crop Health",
      "Crop Water Content": "Crop Water Content",
      "Original Image": "Original Image",
      "Image Date": "Image Date",
      "Search": "Search",
      "Weather Forecast": "Weather Forecast",
      "Generate Report": "Generate Report",
      "Sowing Date:": "Sowing Date:",
      "Harvesting Date:": "Harvesting Date:",
      " Choose one ": "Choose one",
      "yyyy-MM-dd": "yyyy-MM-dd",
      "Kisan": "Kisan",
      "360": "360",
      "Excellent Condition": "Excellent Condition ",
      "Good Condition": "Good Condition",
      "Average Condition": "Average Condition",
      "Poor Condition": "Poor Condition",
      "Very Moist": "Very Moist",
      "Moist": "Moist",
      "Dry": "Dry",
      "Very Dry": "Very Dry",
      "Crop Moisture Status": "Crop Moisture Status",
      "Acres": "Acres",
      "Logout": "Logout",
      "Overall Crop Health Status": "Overall Crop Health Status",
      "Remove Landmark": "Remove Landmark",
      "Legend NDVI": "Legend NDVI",
      "Legend NDMI": "Legend NDMI",
      "Send Feedback": "Send Feedback",
      "Language Switcher": "Language Switcher",
      "My Account": "My Account",
      "Add Crop Details": "Add Crop Details",
      "Field Name": "Field Name",
      "Enter field name": "Enter field name",
      "Crop Type": "Crop Type",
      "Select a crop": "Select a crop",
      "Wheat ": "Wheat",
      "Maize": "Maize",
      "Rice": "Rice",
      "Cotton": "Cotton",
      "Sugarcane": "Sugarcane",
      "Fodder": "Fodder",
      "Other": "Other",
      "Zoom in": "Zoom in",
      "Zoom out": "Zoom out",
      "Locate Me": "Locate Me",
      "Manage your fields remotely": "Manage your fields remotely",
      "Monitor the state of your crops right from the office, learn about the slightest changes on-the-spot, and make fast and reliable decisions on field treatment": "Monitor the state of your crops right from the office, learn about the slightest changes on-the-spot, and make fast and reliable decisions on field treatment",
      "LOGIN TO ACCESS YOUR ACCOUNT" : "LOGIN TO ACCESS YOUR ACCOUNT",
      "Continue with Google" : "Continue with Google",
      "Enter Phone Number" : "Enter Phone Number",
      "Add Field" : "Add Field",
      "Toba Campus" : "Toba Campus",
      "Cas Faisalabad" : "Cas Faisalabad",
      "Devis Vantage" : "Devis Vantage",
      "Okara" : "Okara",
      "Burewala" : "Burewala",
      "Weather" : "Weather",
      "Add your fields" : "Add your fields",
      "Browse satellite images, vegetation indices, weather data, and much more" : " Browse satellite images, vegetation indices, weather data, and much more",
      "Draw your field" : "Draw your field",
      "Select Map" : "Select Map",
      "Field Images" : "Field Images",
      "Update and Fetch Images": "Update and Fetch Images",
      "Total Area" : "Total Area",
      "Satellite Image" : "Satellite Image",
      "Crop Nitrogen Content" : "Crop Nitrogen Content",
      "High" : "High",
      "Moderate" : "Moderate",
      "Low" : "Low",
      
    },
  },
  ur: {
    translation: {
      "Select Farmer": "کسان منتخب کریں",
      "Fetch Images": "تصاویر حاصل کریں",
      "Reset All": "  ری سیٹ کریں",
      "Available images": "دستیاب تصاویر",
      "Select Report Date": "رپورٹ کی تاریخ منتخب کریں",
      "Select Map Type": "نقشہ کی قسم منتخب کریں",
      "Crop Health": "فصل کی صحت",
      "Crop Water Content": "فصل کی پانی کی مقدار",
      "Original Image": "اصل تصویر",
      "Image Date": "تصویر کی تاریخ",
      "Weather Forecast": "موسم کی پیشگوئی",
      "Generate Report": "رپورٹ تیار کریں",
      "Search": " تلاش کریں ",
      "Empty Date": "  خالی",
      "Sowing Date:": "    بوائی کی تاریخ:",
      "Harvesting Date:": " کٹائی کی تاریخ :",
      "Choose one ": "ایک کا انتخاب کریں",
      "yyyy-MM-dd": "ایک کا انتخاب کریں",
      "Kisan": "کسان ",
      "360": "360",
      "Excellent Condition": "بہترین حالت ",
      "Good Condition": "اچھی حالت",
      "Average Condition": " اوسط حالت",
      "Poor Condition": " خراب حالت",
      "Very Moist": "بہت نم",
      "Moist": " نم",
      "Dry": "خشک ",
      "Very Dry": "بہت خشک",
      "Crop Moisture Status": "فصل کی نمی کی کیفیت",
      "Acres": "ایکڑ",
      "Overall Crop Health Status": "فصل کی صحت کی مجموعی صورتحال",
      "Logout": "لاگ آوٹ",
      "Remove Landmark": "لینڈ مارک کو ہٹا دیں",
      "Legend NDVI": " لیجنڈ این ڈی وی آئ ",
      "Legend NDMI": " لیجنڈ این ڈی ایم آئ ",
      "Send Feedback": "تاثرات بھیجیں",
      "Language Switcher": "زبان کو تبدیل کریں",
      "My Account": "میرا اکاؤنٹ",
      "Add Crop Details": "فصل کی تفصیلات شامل کریں",
      "Field Name": "فیلڈ کا نام",
      "Enter field name": "فیلڈ کا نام درج کریں",
      "Crop Type": "فصل کی قسم",
      "Select a crop": "فصل کا انتخاب کریں",
      "Wheat ": "گندم",
      "Maize": "مکئی",
      "Rice": "چاول",
      "Cotton": "کپاس",
      "Sugarcane": "گنے",
      "Fodder": "چارہ",
      "Other": "دیگر",
      "Zoom in": "زوم ان کریں",
      "Zoom out": "زوم آؤٹ کریں",
      "Locate Me": "میرا مقام تلاش کریں",
      "Manage your fields remotely": "گھر سے اپنے کھیتوں کی نگرانی  کریں",
      "Monitor the state of your crops right from the office, learn about the slightest changes on-the-spot, and make fast and reliable decisions on field treatment": "دفتر سے ہی اپنی فصلوں کی حالت کی نگرانی کریں، موقع پر ہی معمولی تبدیلیوں کے بارے میں جانیں، اور کھیت کے علاج کے بارے میں تیز اور قابل اعتماد فیصلے کریں۔",
      "LOGIN TO ACCESS YOUR ACCOUNT" : "اپنے اکاؤنٹ تک رسائی کے لیے لاگ ان کریں۔",
      "Continue with Google" : "گوگل کے ساتھ لاگ ان کریں",
      "Enter Phone Number" : "فون نمبر درج کریں",
      "Add Field" : "اپنی فیلڈ شامل کریں",
      "Toba Campus" : "ٹوبہ کیمپس",
      "Cas Faisalabad" : "کیس فیصل آباد",
      "Devis Vantage" : "ڈیوس وینٹیج",
      "Okara" : "اوکاڑہ",
      "Burewala" : "بورے والا",
      "Weather" : "موسم ",
      "Add your fields" : "اپنی فیلڈز شامل کریں ",
      "Browse satellite images, vegetation indices, weather data, and much more" : "سیٹلائٹ تصاویر، سبزہ انڈیکس، موسمیاتی ڈیٹا اور بہت کچھ دیکھیں ",
      "Draw your field" : "اپنا میدان کھینچیں",
      "Select Map" : "نقشہ منتخب کریں",
      "Field Images" : "فیلڈ امیجز",
      "Update and Fetch Images": "اپ ڈیٹ کریں اور بازیافت کریں",
      "Total Area":"کل رقبہ",
      "Satellite Image" : "سیٹلائٹ امیج",
      "Crop Nitrogen Content" : "فصل کی نائٹروجن کی مقدار",
      "High" : "اعلی",
      "Moderate" : "اعتدال پسند",
      "Low" : "کم",

    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
