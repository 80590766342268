import React, { useState, useRef } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GeoHarvestLogo from "../Assets/Logo/geo-harvest-logo.png";
import DownloadIcon from "@mui/icons-material/Download";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../Language-Switcher/languageSwitcher";

import {
  GoogleMap,
  useLoadScript,
  Polygon,
  GroundOverlay,
} from "@react-google-maps/api";
import { generatePdfDocument } from "../Create-PDF/PDF"; // Import the PDF generation function
import CircularLoader from "../All-Loader/mapLoader"; // Import the CircularLoader component
import LegendNdvi from "../Legend-Gradient/legentNdvi";
import LegendNdmi from "../Legend-Gradient/legentNdmi";
import { SnackbarProvider, useSnackbar } from "notistack";
import SearchLocation from "../Search-Location/searchLocation";
import WeatherWidgets from "../weather-forecast//weatherForcast";
import html2canvas from "html2canvas";
import { predefinedAreas } from "../../utils";

const libraries = ["drawing", "places"];
const EarthEngineData = () => {
  const [startDate, setStartDate] = useState("2024-07-12");
  const [endDate, setEndDate] = useState("2024-07-20");
  const [availableDates, setAvailableDates] = useState([]);
  const [imageCount, setImageCount] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [viewType, setViewType] = useState("");
  const [ndviGradientThumbUrls, setNdviGradientThumbUrls] = useState([]);
  const [ndmiGradientThumbUrls, setNdmiGradientThumbUrls] = useState([]);
  const [ndviThumbUrls, setNdviThumbUrls] = useState([]);
  const [ndmiThumbUrls, setNdmiThumbUrls] = useState([]);
  const [rgbThumbUrls, setRgbThumbUrls] = useState([]);
  const [loading, setLoading] = useState(false); // Full-screen loading state
  const [fetching, setFetching] = useState(false); // Fetch button loading state
  const [error, setError] = useState(null);
  const [aoi, setAoi] = useState([]); // State to store the AOI coordinates
  const [isDownloading, setIsDownloading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [mapLoading, setMapLoading] = useState(false); // Add this line
  const [showWeatherWidget, setShowWeatherWidget] = useState(false);
  const [selectedFarmer, setSelectedFarmer] = useState("");
  const [screenshotUrl, setScreenshotUrl] = useState("");
  const history = useNavigate();
  const [loadingGenerateReport, setLoadingGenerateReport] = useState(false);
  const [selectedCoords, setSelectedCoords] = useState(null);
  const [ndviAreas, setNdviAreas] = useState([]);
  const [ndmiAreas, setNdmiAreas] = useState([]);
  const [loadingFetch, setLoadingFetch] = useState(false); // Separate loading state for fetching
  const { t, i18n } = useTranslation();

  const mapRef = useRef(null);

  const [originalCenter, setOriginalCenter] = useState({
    lat: 31.3656,
    lng: 72.9942,
  });
  const [originalZoom, setOriginalZoom] = useState(13);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyC_4pCYuadBC7676P4zXOFZD9Oc1uDfo0M", // Replace with your Google Maps API key
    libraries,
  });

  // Screen shot work start
  const capturePolygonImage = async (containerId) => {
    const container = document.getElementById(containerId);

    if (!container) {
      console.error(`Container with ID ${containerId} not found.`);
      return null;
    }

    // Wait for all map layers to be fully loaded
    await new Promise((resolve) => setTimeout(resolve, 1000));

    const canvas = await html2canvas(container, {
      useCORS: true, // Enable cross-origin requests
      allowTaint: false, // Do not allow tainted canvases
      logging: true, // Enable logging
      backgroundColor: null, // Capture with a transparent background
    });

    return canvas.toDataURL("image/png");
  };

  const fetchImageCountAndDates = async () => {
    setFetching(true);
    setError(null);
  
    // Determine the base URL based on the environment
    const baseUrl =
      process.env.NODE_ENV === "development"
        ? "http://localhost:8080"
        : "https://api.kisan360.net"; // dynamically set protocol (http or https)  
    try {
      const response = await axios.post(`${baseUrl}/earth-engine`, {
        startDate: "2024-07-12",
        endDate: "2024-07-20",
        polygonPath: aoi,
      });
  
      // Set state with the fetched data
      setImageCount(response.data.imageCount);
      setAvailableDates(response.data.imageDates);
      setNdviThumbUrls(response.data.ndviThumbUrls || []);
      setNdviGradientThumbUrls(response.data.ndviGradientThumbUrls || []);
      setNdmiGradientThumbUrls(response.data.ndmiGradientThumbUrls || []);
      setNdmiThumbUrls(response.data.ndmiThumbUrls || []);
      setRgbThumbUrls(response.data.trueColorThumbUrls || []);
      setNdviAreas(response.data.ndviAreas);
      setNdmiAreas(response.data.ndmiAreas);
  
      if (response.data.imageDates.length > 0) {
        setSelectedDate(response.data.imageDates[0]);
      }
      if (response.data.ndviThumbUrls.length > 0) {
        setViewType("ndvi");
      } else if (response.data.ndmiThumbUrls.length > 0) {
        setViewType("ndmi");
      } else if (response.data.trueColorThumbUrls.length > 0) {
        setViewType("rgb");
      }
  
      if (response.data.imageCount > 0) {
        enqueueSnackbar(`Available images: ${response.data.imageCount}`, {
          variant: "success",
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("Error fetching data. Please check your internet connection.");
      setError(error);
    } finally {
      setFetching(false);
    }
  };
  

  // const sortDatesDescending = (dates) => {
  //   return dates.sort((a, b) => new Date(b) - new Date(a));
  // };

  const handleFetchClick = async () => {
    if (startDate && endDate) {
      setLoadingFetch(true); // Set fetching loading to true at the beginning

      try {
        const mapImage = await capturePolygonImage("map-container");
        setScreenshotUrl(mapImage);

        await fetchImageCountAndDates(); // Fetch image count and dates after capturing screenshot
      } catch (error) {
        console.error("Error capturing screenshot:", error);
      } finally {
        setLoadingFetch(false); // Set fetching loading to false at the end or in case of an error
      }
    } else {
      alert("Please select both start and end dates.");
    }
  };

  const captureAndNavigate = async () => {
    setLoadingGenerateReport(true); // Set loading to true at the beginning

    try {
      const selectedAreaDetails = predefinedAreas.find(
        (area) =>
          area.name.en === selectedFarmer || area.name.ur === selectedFarmer
      );

      // Store the necessary data in local storage
      localStorage.setItem(
        "reportData",
        JSON.stringify({
          screenshotUrl: screenshotUrl, // Use the captured screenshot URL
          areaDetails: selectedAreaDetails,
          reportDate: selectedDate,
          ndviUrl: getImageUrl("ndvi", selectedDate),
          ndmiUrl: getImageUrl("ndmi", selectedDate),
          ndviGradientUrl: getImageUrl("ndviGradient", selectedDate),
          ndmiGradientUrl: getImageUrl("ndmiGradient", selectedDate),
          viewType,
          selectedFarmer,
          ndviAreas: ndviAreasForSelectedDate,
          ndmiAreas: ndmiAreasForSelectedDate,
        })
      );

      // Open a new tab and navigate to the report page
      window.open("/reportPage", "_blank");
    } catch (error) {
      console.error("Error generating report:", error);
    } finally {
      setLoadingGenerateReport(false); // Set loading to false at the end or in case of an error
    }
  };

  const handleAreaSelect = (event) => {
    const selectedName = event.target.value;

    // Find the area based on the selected name and current language
    const selectedArea = predefinedAreas.find(
      (area) => area.name[i18n.language] === selectedName
    );

    setSelectedFarmer(selectedName); // Set the selected farmer's name

    // Clear previous state
    setAoi([]);
    setImageCount(null);
    setAvailableDates([]);
    setSelectedDate("");
    setViewType("");
    setNdviThumbUrls([]);
    setNdmiThumbUrls([]);
    setRgbThumbUrls([]);
    setMapLoading(true); // Show loading state in the map section

    if (selectedArea) {
      setAoi(selectedArea.coords);
      setSelectedCoords(selectedArea.coords);

      // Fit map to selected area
      const bounds = new window.google.maps.LatLngBounds();
      selectedArea.coords.forEach((coord) => bounds.extend(coord));

      if (mapRef.current) {
        mapRef.current.fitBounds(bounds);
      }
    }

    setTimeout(() => setMapLoading(false), 1500); // Simulate loading for area selection
  };

  const handleDateSelect = (event) => {
    setSelectedDate(event.target.value);
    setLoading(true); // Start loading
    // Simulate loading for date selection
    setTimeout(() => setLoading(false), 2500);
  };

  const handleViewTypeSelect = (type) => {
    setViewType(type);
    setLoading(true); // Start loading
    // Simulate loading for view type selection
    setTimeout(() => setLoading(false), 2500);
  };

  const getImageUrl = (type, date) => {
    const index = availableDates.indexOf(date);
    if (index === -1) return null;
    switch (type) {
      case "ndvi":
        return ndviThumbUrls[index] || null;
      case "ndmi":
        return ndmiThumbUrls[index] || null;
      case "rgb":
        return rgbThumbUrls[index] || null;
      case "ndviGradient":
        return ndviGradientThumbUrls[index] || null;
      case "ndmiGradient":
        return ndmiGradientThumbUrls[index] || null;
      default:
        return null;
    }
  };

  //   const handleLoad = (mapInstance) => {
  //     setOriginalCenter(mapInstance.getCenter());
  //     setOriginalZoom(mapInstance.getZoom());

  //     const drawingManager = new window.google.maps.drawing.DrawingManager({
  //       drawingMode: null,
  //       drawingControl: true,
  //       drawingControlOptions: {
  //         position: window.google.maps.ControlPosition.LEFT_CENTER,
  //         drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
  //       },
  //       polygonOptions: {
  //         fillColor: "#FF0000",
  //         fillOpacity: 0,
  //         strokeColor: "#FF0000",
  //         strokeOpacity: 1.5,
  //         strokeWeight: 1.5,
  //         clickable: false,
  //         editable: true,
  //         draggable: false,
  //         geodesic: false,
  //       },
  //     });

  //     drawingManager.setMap(mapInstance);

  //     window.google.maps.event.addListener(
  //       drawingManager,
  //       "overlaycomplete",
  //       (event) => {
  //         if (event.type === window.google.maps.drawing.OverlayType.POLYGON) {
  //           const coordinates = event.overlay
  //             .getPath()
  //             .getArray()
  //             .map((coord) => ({
  //               lat: coord.lat(),
  //               lng: coord.lng(),
  //             }));
  //           setAoi(coordinates); // Set AOI in component state
  //         }
  //       }
  //     );
  //   };

  const handleLocationSelect = ({ lat, lng }) => {
    setOriginalCenter({ lat, lng });
    setOriginalZoom(15); // Adjust the zoom level as needed
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading...</div>;

  const bounds = aoi.length > 0 && {
    north: Math.max(...aoi.map((coord) => coord.lat)),
    south: Math.min(...aoi.map((coord) => coord.lat)),
    east: Math.max(...aoi.map((coord) => coord.lng)),
    west: Math.min(...aoi.map((coord) => coord.lng)),
  };

  const getFilteredAreas = (areas, selectedDate) => {
    return areas.filter((area) => area.date === selectedDate);
  };
  const getAreasForSelectedDate = (areas, availableDates, selectedDate) => {
    const dateIndex = availableDates.indexOf(selectedDate);
    return dateIndex !== -1 ? areas[dateIndex] : null;
  };
  const ndviAreasForSelectedDate = getAreasForSelectedDate(
    ndviAreas,
    availableDates,
    selectedDate
  );
  const ndmiAreasForSelectedDate = getAreasForSelectedDate(
    ndmiAreas,
    availableDates,
    selectedDate
  );

  return (
    <div>
      {loading && <CircularLoader />} {/* Show loader when loading */}
      <div className="navbar-parent">
        <nav
          className="navbar navbar-light"
          style={{ background: "linear-gradient(180deg, #42424a, #191919)" }}
        >
          <a className="navbar-brand" href="/">
            {/* <img
              src={GeoHarvestLogo}
              width="40"
              height="40"
              className="align-center"
              alt=""
            /> */}
            <h3 style={{ color: "white", margin: "5px" }}>
              {" "}
              <span
                style={{
                  background: "#198754",
                  borderRadius: "5px",
                  padding: "3px",
                }}
              >
                {t("Kisan")}
              </span>
              {t("360")}
            </h3>{" "}
          </a>
          <div className="" style={{ marginLeft: "20px" }}>
            <SearchLocation onSelect={handleLocationSelect} />
          </div>
          <div>
            <LanguageSwitcher />
          </div>
        </nav>
      </div>
      <div className="row" style={{ padding: "10px" }}>
        <div className="col-lg-2">
          <div className="d-flex flex-column mb-2 gap-3">
            <div className="d-flex flex-column mb-4">
              <label style={{ fontWeight: "bolder" }}>
                {t("Select Farmer")}:{" "}
              </label>
              <select onChange={handleAreaSelect}>
                <option value="">{t("Select Farmer")}</option>
                {predefinedAreas.map((area) => (
                  <option key={area.name.en} value={area.name[i18n.language]}>
                    {area.name[i18n.language]}
                  </option>
                ))}
              </select>
            </div>

            <div className="gap-2 d-lg-flex">
              <button
                style={{ width: "150px" }} // Set a fixed width for the button
                className="btn btn-success btn-sm"
                onClick={handleFetchClick}
                disabled={loadingFetch} // Disable button when fetching
              >
                {loadingFetch ? (
                  <CircularProgress size={16} sx={{ color: "white" }} />
                ) : (
                  t("Fetch Images")
                )}
              </button>
            </div>
            <a href="/Sentinel2HarmonizedStatic">
              <button
                className="btn btn-success btn-sm "
                style={{ width: "150px" }} // Set a fixed width for the button

                //   style={{ padding: "5.9px", lineHeight: "1.5" }}
              >
                {t("Reset All")}
              </button>
            </a>

            <p>
              <span style={{ fontWeight: "bolder" }}>
                {t("Available images")}:
              </span>
              {""}
              <span
                style={{ color: "red", fontWeight: "bolder", margin: "3px" }}
              >
                {imageCount ? imageCount : "0"}
              </span>
            </p>
          </div>
          <div className="d-flex flex-column mb-3">
            <label style={{ fontWeight: "bolder" }}>
              {t("Select Report Date")}:{" "}
            </label>
            <select
              value={selectedDate}
              onChange={handleDateSelect}
              disabled={!imageCount && !imageCount}
            >
              {/* <option value="">Select Date</option> */}
              {availableDates.map((date) => (
                <option key={date} value={date}>
                  {date}
                </option>
              ))}
            </select>
          </div>

          <div className="d-flex flex-column mb-4">
            <label style={{ fontWeight: "bolder" }}>
              {t("Select Map Type")}:
            </label>
            <select
              value={viewType}
              onChange={(e) => handleViewTypeSelect(e.target.value)}
              disabled={!ndviThumbUrls.length && !ndmiThumbUrls.length} // Disable if no images are available
            >
              {/* <option value="">Select View Type</option> */}
              <option value="ndvi">{t("Crop Health")}</option>
              <option value="ndmi">{t("Crop Water Content")}</option>
              <option value="rgb">{t("Original Image")}</option>
              {/* <option value="ndviGradient">NDVI Gradient</option> */}
              {/* <option value="ndmiGradient">NDMI Gradient</option> */}
            </select>
          </div>
          <div>
            <p>
              <span style={{ fontWeight: "bold" }}>{t("Image Date")}:</span>{" "}
              <span style={{ color: "red" }}>
                {selectedDate ? selectedDate : t("Empty Date")}
              </span>
            </p>
          </div>
          <div className=" flex-column justify-content-center ">
            <div>
              <button
                className="btn btn-success btn-sm mt-2"
                onClick={() => setShowWeatherWidget(!showWeatherWidget)}
                style={{ width: "150px" }} // Set a fixed width for the button
                disabled={!ndviThumbUrls.length && !ndmiThumbUrls.length} // Disable if no images are available or downloading is in progress
              >
                {t("Weather Forecast")}
              </button>
            </div>
            <div>
              <button
                className="btn btn-success btn-sm mt-2"
                onClick={captureAndNavigate}
                disabled={!ndviThumbUrls.length && !ndmiThumbUrls.length} // Disable if no images are available or downloading is in progress
                style={{ width: "150px" }}
              >
                {loadingGenerateReport ? (
                  <CircularProgress size={16} sx={{ color: "white" }} />
                ) : (
                  t("Generate Report")
                )}
              </button>
            </div>
          </div>
        </div>
        <div className="col-lg-10">
          <div style={{ position: "relative" }}>
            {mapLoading && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "90vh",
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  zIndex: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            )}
            <GoogleMap
              id="map-container"
              mapContainerStyle={{ height: "85vh", width: "100%" }}
              zoom={originalZoom}
              center={originalCenter}
              onLoad={(map) => {
                // handleLoad(map);
                mapRef.current = map;
              }}
              mapTypeId="satellite"
            >
              {aoi.length > 0 && (
                <Polygon
                  path={aoi}
                  options={{
                    fillColor: "#FF0000",
                    fillOpacity: 0,
                    strokeColor: "#FF0000",
                    strokeOpacity: 2,
                    strokeWeight: 2,
                    clickable: false,
                    editable: true,
                    draggable: false,
                    geodesic: false,
                  }}
                />
              )}
              {selectedDate && viewType && (
                <GroundOverlay
                  key={`${viewType}-${selectedDate}-${new Date().getTime()}`}
                  url={getImageUrl(viewType, selectedDate)}
                  bounds={bounds}
                />
              )}

              <div
                style={{
                  position: "absolute",
                  bottom: 20,
                }}
              >
                {viewType === "ndvi" && (
                  <LegendNdvi
                    ndviAreas={ndviAreasForSelectedDate}
                    ndmiAreas={ndmiAreasForSelectedDate}
                  />
                )}
                {viewType === "ndmi" && (
                  <LegendNdmi
                    ndviAreas={ndviAreasForSelectedDate}
                    ndmiAreas={ndmiAreasForSelectedDate}
                  />
                )}
                <div style={{ zIndex: "2" }}>
                  {showWeatherWidget && selectedCoords && (
                    <WeatherWidgets coordinates={selectedCoords} />
                  )}
                </div>
              </div>
            </GoogleMap>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EarthEngineData;

